import React, { useEffect, useState } from "react";
import {
  Button,
  Confirm,
  Form,
  Header,
  Message,
  Select,
} from "semantic-ui-react";
import { dnsService } from "domain/service";
import RecordSet, { DNSRecord } from "domain/model/DNS/DNSRecordSet";
import { DNSRecordSetTable } from "components/SiteManage/Content/DNSRecord/DNSRecordSetTable";
import { useFormik } from "formik";
import * as Yup from "yup";
import MESSAGE from "config/message.json";
import {
  DNS_RECORD_TYPE,
  WRITABLE_DNS_RECORD_TYPE,
} from "domain/model/DNS/DNSRecordType";
import { dnsRepository } from "domain/repository";
import { RavenLoader } from "components/lib/RavenLoader";

const validationSchema = Yup.object({
  name: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  type: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  value: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

type Props = { storeId: string };
export const DNSRecordSet: React.FC<Props> = ({ storeId }) => {
  const [recordSets, setRecordSets] = useState<RecordSet[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [forRendering, setForRendering] = useState({});

  const [
    deleteTargetRecordSet,
    setDeleteTargetRecordSet,
  ] = useState<DNSRecord | null>(null);
  const [deleting, setDeleting] = useState(false);

  const [isMx, setIsMx] = useState(false);
  const [isTxt, setIsTxt] = useState(false);

  useEffect(() => {
    setLoading(true);
    dnsService
      .fetchDnsRecordSets(storeId)
      .then((res) => {
        setRecordSets(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storeId, forRendering]);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: DNS_RECORD_TYPE.A,
      value: "",
    },
    validationSchema,
    onSubmit: ({ name, type, value }, formikHelpers) => {
      setErrorMsg(null);
      dnsRepository
        .addDnsRecord(storeId, { name, type, value })
        .then(() => {
          formikHelpers.resetForm();
          setForRendering({});
        })
        .catch(() => {
          setErrorMsg("追加に失敗しました。値が適切か確認してください。");
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const handleDeleteButton = (recordSet: DNSRecord) => {
    setDeleteTargetRecordSet(recordSet);
  };
  const handleDelete = () => {
    if (deleteTargetRecordSet) {
      setDeleting(true);
      dnsRepository
        .deleteDnsRecord(storeId, deleteTargetRecordSet)
        .then(() => {
          setForRendering({});
        })
        .finally(() => {
          setDeleteTargetRecordSet(null);
          setDeleting(false);
        });
    }
  };

  if (loading || recordSets === null) {
    return <RavenLoader active />;
  }

  const flattenRecordSets = recordSets
    .map(({ name, type, values }) => {
      return values.map(({ value }) => ({
        name,
        type,
        value,
      }));
    })
    .flat();

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <>
      <RavenLoader active={deleting} content="削除中" />
      <Confirm
        open={deleteTargetRecordSet !== null}
        content="レコードを削除してもよろしいですか？"
        onCancel={() => {
          setDeleteTargetRecordSet(null);
        }}
        onConfirm={handleDelete}
      />
      <div>
        <Header content="DNSレコードの追加" dividing />
        <Form onSubmit={handleSubmit}>
          <Form.Group inline>
            <Form.Input
              name="name"
              label="name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
              width="4"
            />
            <Form.Field>
              <label>type</label>
              <Select
                options={Object.entries(WRITABLE_DNS_RECORD_TYPE).map(
                  ([key, value]) => {
                    return {
                      key,
                      value: key,
                      text: value,
                    };
                  }
                )}
                onChange={(_, { value }) => {
                  setFieldValue("type", value);
                  if (value === "MX") {
                    setIsMx(true);
                  } else {
                    setIsMx(false);
                  }
                  if (value === "TXT") {
                    setIsTxt(true);
                  } else {
                    setIsTxt(false);
                  }
                }}
                value={values.type}
              />
            </Form.Field>
            <Form.Input
              name="value"
              label="value"
              value={values.value}
              onChange={handleChange}
              error={errors.value}
              width="4"
            />
            <Button primary type="submit" loading={isSubmitting}>
              追加
            </Button>
          </Form.Group>
        </Form>
        {errorMsg ? <Message negative content={errorMsg} size="tiny" /> : null}
        {isMx ? (
          <Message warning>
            <Message.Header>valueの前に優先度を入力して下さい</Message.Header>
            <p>例：10 example.amazonaws.com</p>
          </Message>
        ) : null}
        {isTxt ? (
          <Message warning>
            <Message.Header>
              Valueをダブルクォーテーション""で囲む必要があります
            </Message.Header>
            <p>例："example="</p>
          </Message>
        ) : null}
      </div>

      <Header content="登録済みのDNSレコード" dividing />
      <DNSRecordSetTable
        recordSets={flattenRecordSets}
        handleDeleteButton={handleDeleteButton}
      />
    </>
  );
};
