import React from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { Button, Form, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { JobType, SalaryType } from "domain/model/JobFeed";

type SubmitType = typeof SubmitTypes[keyof typeof SubmitTypes];

export const SubmitTypes = {
  CREATE: "作成",
  UPDATE: "更新",
} as const;

type FormValues = {
  url: string;
  title: string;
  company: string;
  jobType: JobType;
  description: string;
  postDate: Date;
  salaryType: SalaryType;
  salaryMin: number;
  salaryMax: number;
  state: string | null;
  city: string | null;
  address: string | null;
  station: string | null;
  experience: string | null;
  workingTime: string | null;
  imageUrl: string | null;
};

type Props = {
  initialValues: FormValues;
  isAgency: boolean;
  storeId: string | undefined;
  submitType: SubmitType;
  handleSubmit: ({
    values,
    setStatus,
    resetForm,
  }: {
    values: FormValues;
    setStatus: (status: { success: string; error: string }) => void;
    resetForm: () => void;
  }) => void;
};

const validationSchema = Yup.object().shape({
  url: Yup.string().required(),
  title: Yup.string().required(),
  company: Yup.string().required(),
  description: Yup.string().required(),
  postDate: Yup.string().required(),
});

export const JobFeedForm: React.FC<Props> = ({
  initialValues,
  storeId,
  isAgency,
  submitType,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, resetForm }) => {
      handleSubmit({ values, setStatus, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const jobTypeOptions = [
    { key: "full_time", value: "full_time", text: "正社員" },
    { key: "part_time", value: "part_time", text: "パート・アルバイト" },
    { key: "contractor", value: "contractor", text: "契約社員" },
    { key: "temporary", value: "temporary", text: "一時的な雇用" },
    { key: "intern", value: "intern", text: "インターンシップ" },
    { key: "volunteer", value: "volunteer", text: "ボランティア" },
    { key: "per_diem", value: "per_diem", text: "日雇い" },
    { key: "other", value: "other", text: "その他" },
  ];

  const salaryTypeOptions = [
    { key: "hour", value: "hour", text: "時間給" },
    { key: "day", value: "day", text: "日給" },
    { key: "week", value: "week", text: "週給" },
    { key: "month", value: "month", text: "月給" },
    { key: "year", value: "year", text: "年給・年俸" },
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      {formik.status.error && (
        <Segment
          inverted
          color="red"
          secondary
          style={{ marginBottom: "2rem" }}
        >
          {formik.status.error}
        </Segment>
      )}

      <Form.Field required>
        <label>遷移先URL</label>
        <Form.Input
          name="url"
          error={formik.errors.url}
          value={formik.values.url}
          onChange={formik.handleChange}
          placeholder="http://example.com"
        />
      </Form.Field>

      <Form.Field required>
        <label>見出し(職種)</label>
        <Form.Input
          name="title"
          error={formik.errors.title}
          value={formik.values.title}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field required>
        <label>会社名</label>
        <Form.Input
          name="company"
          error={formik.errors.company}
          value={formik.values.company}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field required>
        <label>雇用形態</label>
        <Form.Select
          required
          name="jobType"
          options={jobTypeOptions}
          error={formik.errors.jobType}
          value={formik.values.jobType}
          onChange={(_, { value }) => formik.setFieldValue("jobType", value)}
        />
      </Form.Field>

      <Form.Field required>
        <label>仕事内容</label>
        <Form.TextArea
          name="description"
          error={formik.errors.description}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field required>
        <label>掲載開始日</label>
        <Form.Input
          type="date"
          name="postDate"
          error={formik.errors.postDate}
          value={formik.values.postDate}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>給与区分</label>
        <Form.Select
          required
          name="salaryType"
          options={salaryTypeOptions}
          error={formik.errors.salaryType}
          value={formik.values.salaryType}
          onChange={(_, { value }) => formik.setFieldValue("salaryType", value)}
        />
      </Form.Field>

      <Form.Field>
        <label>給与(Min)</label>
        <Form.Input
          name="salaryMin"
          error={formik.errors.salaryMin}
          value={formik.values.salaryMin}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>給与(Max)</label>
        <Form.Input
          name="salaryMax"
          error={formik.errors.salaryMax}
          value={formik.values.salaryMax}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>勤務地(都道府県)</label>
        <Form.Input
          name="state"
          error={formik.errors.state}
          value={formik.values.state}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>勤務地(市区町村)</label>
        <Form.Input
          name="city"
          error={formik.errors.city}
          value={formik.values.city}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>勤務地(番地等)</label>
        <Form.Input
          name="address"
          error={formik.errors.address}
          value={formik.values.address}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>勤務地の最寄り駅</label>
        <Form.Input
          name="station"
          error={formik.errors.station}
          value={formik.values.station}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>経験・資格</label>
        <Form.Input
          name="experience"
          error={formik.errors.experience}
          value={formik.values.experience}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>勤務時間</label>
        <Form.Input
          name="workingTime"
          error={formik.errors.workingTime}
          value={formik.values.workingTime}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>画像URL</label>
        <Form.Input
          name="imageUrl"
          error={formik.errors.imageUrl}
          value={formik.values.imageUrl}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <div style={{ textAlign: "center" }}>
        <Button color="blue" content={submitType} type="submit" />
      </div>
    </Form>
  );
};

const FormHelper = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const ErrorMessage = styled.div`
  color: #f55555;
  font-weight: bolder;
`;
