import React, { useState } from "react";
import { Version } from "domain/model/Version";
import { useFormik } from "formik";
import { StoreContainer, VersionContainer } from "containers";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { VersionListItem } from "components/Version/Index/VersionItem";
import { Form } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { getPreviewUrl } from "utils/version";

type Props = {
  index: number;
  version: Version;
  isAgency: boolean;
  storeId: string | undefined;
};
export const VersionListItemContainer: React.FC<Props> = ({
  isAgency,
  storeId,
  version,
  index,
}) => {
  const storeContainer = StoreContainer.useContainer();
  const { currentStore } = storeContainer;

  const versionContainer = VersionContainer.useContainer();
  const {
    editVersion,
    setEditVersion,
    downloadFile,
    updateTitle,
    rollback,
  } = versionContainer;
  const versionData = version.data;

  const [isRollbackLoading, setIsRollbackLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();

  const handleClickDownload = () => {
    downloadFile({
      isAgency,
      storeId,
      versionHash: versionData.versionHash,
      filename: versionData.title,
    });
  };

  const handleClickVersionShow = () => {
    const url = isAgency
      ? generatePath(routes.agency.versionShow.pathname, {
          storeId,
        })
      : routes.store.versionShow.pathname;
    history.push(url, {
      version,
    });
  };

  const handleClickPreview = () => {
    if (!currentStore) {
      return;
    }
    const id = currentStore.id;
    const s3Bucket = currentStore.awsResource.s3Bucket;
    const previewUrl = getPreviewUrl(versionData.versionHash, id, s3Bucket);
    window.open(previewUrl);
  };

  const handleClickRollback = () => {
    setIsRollbackLoading(true);
    rollback({
      isAgency,
      storeId,
      versionHash: versionData.versionHash,
    }).finally(() => {
      setIsRollbackLoading(false);
    });
  };

  const handleEditButton = () => {
    if (editVersion === versionData.versionHash) {
      setEditVersion(null);
    } else {
      setEditVersion(versionData.versionHash);
    }
  };

  const formik = useFormik({
    initialValues: { title: versionData.title ?? "" },
    initialStatus: { error: false },
    onSubmit: (values, formikHelpers) => {
      if (values.title.length === 0) {
        formikHelpers.setStatus({ error: true });
        formikHelpers.setSubmitting(false);
        return;
      }
      updateTitle({
        isAgency,
        storeId,
        versionHash: versionData.versionHash,
        title: values.title,
      })
        .then(() => {
          setEditVersion(null);
          formikHelpers.setStatus({ error: false });
        })
        .catch(() => {
          formikHelpers.setStatus({ error: true });
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const titleEditInputOrValue =
    editVersion === versionData.versionHash ? (
      <Form onSubmit={formik.handleSubmit}>
        <Form.Input
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.status.error}
          action={{
            color: "blue",
            content: "更新",
            loading: formik.isSubmitting,
          }}
        />
      </Form>
    ) : (
      versionData.title
    );

  return (
    <VersionListItem
      titleEditInputOrValue={titleEditInputOrValue}
      index={index}
      versionData={version.data}
      editVersion={editVersion}
      handleClickDownload={handleClickDownload}
      handleClickRollback={handleClickRollback}
      handleClickPreview={handleClickPreview}
      handleClickVersionShow={handleClickVersionShow}
      isRollbackLoading={isRollbackLoading}
      headVersion={currentStore?.headVersion}
      handleEditButton={handleEditButton}
      isMobile={isMobile}
    />
  );
};
