import React from "react";
import {
  useLocation,
  useHistory,
  useParams,
  generatePath,
} from "react-router-dom";
import { CopyModal, FormTypes } from "components/lib/CopyModal";
import PageContainer from "components/lib/PageContainer";
import { Button, Segment } from "semantic-ui-react";
import routes from "utils/routes";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import JobOfferFormList from "components/JobOffer/JobOfferFormIndex/JobOfferFormList";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const JobOfferFormIndex: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const { state } = useLocation<{ savedFormDom: string }>();

  const savedFormDom = state === undefined ? undefined : state.savedFormDom;

  const history = useHistory();

  return (
    <>
      <CopyModal savedFormDom={savedFormDom} formType={FormTypes.JOB_OFFER} />
      <PageContainer
        header="求人票一覧"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
          "求人票一覧"
        )}
        action={
          <Button
            color="blue"
            content="新規求人票作成"
            onClick={() => {
              const formNewUrl = isAgency
                ? generatePath(routes.agency.jobOfferFormNew.pathname, {
                    storeId,
                  })
                : routes.store.jobOfferFormNew.pathname;
              history.push(formNewUrl);
            }}
          />
        }
      >
        <Segment>
          <JobOfferFormList isAgency={isAgency} storeId={storeId} />
        </Segment>
      </PageContainer>
    </>
  );
};

export default JobOfferFormIndex;
