import * as Yup from "yup";
import message from "config/message.json";
import { FormTypes } from "domain/model/ContactFormTypes";

export const FormTypeLabel = {
  [FormTypes.SINGLE_LINE_TEXT]: "一行テキスト",
  [FormTypes.MULTIPLE_LINE_TEXT]: "複数行テキスト",
  [FormTypes.EMAIL]: "メールアドレス",
  [FormTypes.SELECT]: "セレクトボックス",
  [FormTypes.RADIO]: "ラジオボタン",
  [FormTypes.DATE]: "日付",
  [FormTypes.CHECKBOX]: "チェックボックス",
} as const;

export const validationSchema = Yup.object().shape({
  formContent: Yup.object().shape({
    formName: Yup.string(),
    pubEmail: Yup.string()
      .required(message.ERROR.NO_INPUT)
      .email(message.ERROR.INVALID_EMAIL),
    redirectUrl: Yup.string()
      .required(message.ERROR.NO_INPUT)
      .url(message.ERROR.INVALID_URL),
    forms: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        type: Yup.string(),
        options: Yup.array(),
        required: Yup.boolean(),
      })
    ),
  }),
});
