import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { versionService } from "domain/service";

import { Version } from "domain/model/Version";
import { ProgressBarStatus } from "components/lib/ProgressBar";
import { versionRepository } from "domain/repository";
import { StoreContainer } from "containers/index";

const VersionContainer = () => {
  const [versions, setVersions] = useState<Version[] | null>(null);
  const [editVersion, setEditVersion] = useState<string | null>(null);
  const [hasMoreVersions, setHasMoreVersions] = useState<boolean | null>(false);
  const [loading, setLoading] = useState(false);

  const [downloadStatus, setDownloadStatus] = useState<ProgressBarStatus>(null);

  const storeContainer = StoreContainer.useContainer();

  const fetchVersions = useCallback(
    async (
      isAgency: boolean,
      storeId: string | undefined,
      createdAt?: string
    ) => {
      setLoading(true);
      const lastFetchedVersion = await versionService
        .fetchVersions(isAgency, storeId, createdAt)
        .then((fetchedVersions) => {
          setVersions((currentState) => {
            if (currentState === null) {
              return fetchedVersions;
            } else {
              return [...currentState, ...fetchedVersions];
            }
          });
          // 10件以下の場合は次のバージョンが10件あるかはチェックしない
          if (fetchedVersions.length < 10) {
            return undefined;
          }
          return fetchedVersions[fetchedVersions.length - 1];
        });
      setLoading(false);
      //  次のバージョンが10件あるかチェック
      if (lastFetchedVersion === undefined) {
        return;
      }
      versionService
        .fetchVersions(isAgency, storeId, lastFetchedVersion.data.createdAt)
        .then((fetchedNextVersions) => {
          if (fetchedNextVersions.length !== 0) {
            setHasMoreVersions(true);
          } else {
            setHasMoreVersions(false);
          }
        });
    },
    []
  );

  const downloadFile = ({
    isAgency,
    storeId,
    versionHash,
    filename,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    versionHash: string;
    filename: string;
  }) => {
    setDownloadStatus("active");
    versionService
      .downloadVersion(isAgency, storeId, { versionHash })
      .then((res) => {
        const uri = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.download = filename;
        link.href = uri;
        link.click();
        link.remove();
        URL.revokeObjectURL(uri);
        setDownloadStatus("success");
      })
      .catch(() => {
        setDownloadStatus("error");
      })
      .then(() => {
        return new Promise((resolve) =>
          setTimeout(() => {
            setDownloadStatus(null);
            resolve();
          }, 2000)
        );
      });
  };

  const updateTitle = ({
    isAgency,
    storeId,
    versionHash,
    title,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    versionHash: string;
    title: string;
  }) => {
    return versionRepository
      .update(isAgency, storeId, versionHash, title)
      .then(() => {
        // 更新後のtitleを反映する
        setVersions((currentState) => {
          if (currentState === null) {
            return null;
          }
          return currentState?.map((v) => {
            if (v.data.versionHash === versionHash) {
              return {
                ...v,
                data: {
                  ...v.data,
                  title: title,
                },
              };
            }
            return v;
          });
        });
      })
      .catch(() => {});
  };

  const rollback = ({
    isAgency,
    storeId,
    versionHash,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    versionHash: string;
  }) => {
    return versionRepository
      .rollback(isAgency, storeId, versionHash)
      .then(() => {
        storeContainer.changeHeadVersion(versionHash);
      });
  };

  return {
    loading,
    versions,
    editVersion,
    fetchVersions,
    setEditVersion,
    hasMoreVersions,
    downloadFile,
    downloadStatus,
    updateTitle,
    rollback,
  };
};

export default createContainer(VersionContainer);
