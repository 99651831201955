import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { SelectMediaModal } from "components/lib/SelectMediaModal";

type Props = {
  source: string;
  onChange: (value: string) => void;
  mediaUrls: string[];
  refetchMediaUrls: () => void;
  body: { id: string; className: string };
  documentBaseUrl: string;
};

// TODO: TinyMCEのモーダルとsemantic-uiのモーダルのz-indexを調整するために、本当はしたくないが、index.cssでスタイルを上書きしている。
export const FullHtmlWysiwygEditor: React.FC<Props> = ({
  source,
  onChange,
  mediaUrls,
  refetchMediaUrls,
  body,
  documentBaseUrl,
}) => {
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [mediaCallback, setMediaCallback] = useState<{
    callback: Function;
  } | null>(null);

  return (
    <>
      <SelectMediaModal
        title="画像を選択"
        open={openMediaModal}
        mediaUrls={mediaUrls}
        onUploaded={refetchMediaUrls}
        handleMediaClick={(url) => {
          if (mediaCallback !== null) {
            mediaCallback.callback(url);
            setOpenMediaModal(false);
          }
        }}
        close={() => setOpenMediaModal(false)}
      />
      <Editor
        initialValue={source}
        apiKey={process.env.REACT_APP_TYNY_MCE_API_KEY}
        init={{
          height: 800,
          menubar: false,
          body_id: body.id,
          body_class: body.className,
          document_base_url: documentBaseUrl,
          plugins: ["fullpage", "link", "fullscreen", "image", "contextmenu"],
          contextmenu:
            "link image cut copy paste inserttable | cell row column deletetable",
          toolbar: "undo redo | fullscreen | image",
          language: "ja",
          branding: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar:
            "styleselect | fontsizeselect | forecolor backcolor | quicklink | ",
          // TinyMCEがHTMLを加工しないように制限するオプション
          verify_html: false,
          convert_urls: false,
          // a タグの中の div が消えないようにする
          valid_children: "+a[div|p]",
          // p タグが勝手に挿入されないようにする
          forced_root_block: false,
          file_picker_callback: function (
            callback: Function,
            value: string,
            meta: Record<string, any>
          ) {
            if (meta.filetype === "image") {
              setMediaCallback({ callback });
              setOpenMediaModal(true);
            }
          },
        }}
        onEditorChange={onChange}
      />
    </>
  );
};
