import React, { useState } from "react";
import { Modal, Header, Button } from "semantic-ui-react";
import { clipboardWriteText } from "utils/copyText";

export const FormTypes = {
  JOB_OFFER: "求人",
  CONTACT: "問い合わせ",
} as const;

type FormType = typeof FormTypes[keyof typeof FormTypes];

type Props = {
  savedFormDom: string | undefined;
  formType: FormType;
};

export const CopyModal: React.FC<Props> = ({ savedFormDom, formType }) => {
  const [open, setOpen] = useState(savedFormDom !== undefined);

  const cancelModal = () => {
    setOpen(false);
  };

  const copyDom = () => {
    if (savedFormDom !== undefined) {
      clipboardWriteText(savedFormDom);
    }

    setOpen(false);
  };
  return (
    <Modal open={open}>
      <Modal.Header content="HTMLコードのコピー" />
      <Modal.Content>
        <Modal.Description>
          <Header
            icon="file alternate outline"
            content="保存したフォームのHTMLコードを今すぐコピーしますか?"
          />
          <p>
            コピーしたコードをHTMLファイルに貼り付けることで{formType}
            フォームが作成できます。
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cancelModal}>キャンセル</Button>
        <Button positive onClick={copyDom}>
          コピー
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
