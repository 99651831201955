import React from "react";

import { StoreContainer, UserContainer } from "containers";

import PageContainer from "components/lib/PageContainer";
import DomainRegisterMenu from "components/SiteManage/Content/Domain/DomainRegisterMenu";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { DomainInformationContainer } from "components/SiteManage/Content/Domain/DomainInformation/DomainInformationContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

type Props = { storeId: string | undefined };
const Container: React.FC<Props> = ({ storeId }) => {
  const storeContainer = StoreContainer.useContainer();
  const { currentStore } = storeContainer;

  if (currentStore?.awsResource.domain) {
    return (
      <DomainInformationContainer
        awsResource={currentStore.awsResource}
        awsState={currentStore.awsState}
      />
    );
  }

  return <DomainRegisterMenu storeId={storeId} />;
};

export const Domain: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  return (
    <PageContainer
      header="ドメイン設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("ドメイン設定")}
    >
      <Container storeId={storeId} />
    </PageContainer>
  );
};
