import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Segment, Loader, Header, Message, Checkbox } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionTakeoutStatus from "components/lib/OptionTakeoutStatus";

import { takeoutRepository } from "domain/repository";

import { UserContainer, StoreContainer } from "containers";

import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const agencyEnabled = AGENCY[window.location.hostname].options.takeout;

  if (!agencyEnabled) {
    return <NotValidOption heading="テイクアウト機能" />;
  }

  if (!currentStore || loading) {
    return <Loader active />;
  }

  const handleClickEnable = (useAsEc: boolean) => {
    if (!storeId) {
      return;
    }
    setHasError(false);
    setLoading(true);
    takeoutRepository
      .enable(isAgency, storeId, useAsEc)
      .then(() => changeCurrentStore(isAgency, storeId))
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { useTakeout, takeoutUseAsEc } = currentStore.property;

  return (
    <>
      <Header content="テイクアウト機能オプション利用状況" dividing />
      <OptionTakeoutStatus
        enabled={useTakeout}
        toEnable={handleClickEnable}
        isAgency={isAgency}
      />
      <br />
      <Checkbox
        checked={takeoutUseAsEc}
        label="簡易EC機能として利用"
        disabled
      />
      {hasError && (
        <Message
          error
          content="テイクアウト機能の有効化に失敗しました。テイクアウト機能を利用できない場合は、運営にお問い合わせください。"
        />
      )}
    </>
  );
};

const Takeout = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="テイクアウト機能"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("テイクアウト機能")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};

export default Takeout;
