import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { StoreContainer, UserContainer } from "containers";
import { orderService } from "domain/service";
import Order, {
  makePaymentTypeLabel,
  makeStatusLabel,
} from "domain/model/Order";
import { orderRepository } from "domain/repository";
import dayjs from "dayjs";

export const OrderDetail = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId, id } = useParams<StoreIdParam & { id: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;

  useEffect(() => {
    orderService.fetchOrder(isAgency, storeId, id).then((res) => setOrder(res));
  }, [id, isAgency, storeId]);

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  if (order === null) {
    return <Loader active />;
  }

  const cancel = async () => {
    // TODO モーダルなどで詳細表示
    if (
      !window.confirm(
        "受注をキャンセルしてもよろしいですか？キャンセル後は取り消すことはできません。"
      )
    ) {
      return;
    }

    await orderRepository.changeStatusToCancel(
      isAgency,
      storeId,
      String(order.id)
    );
    window.location.reload();
  };

  const totalPrice = order.orderDetails.reduce(
    (acc, { unitPrice, number }) => acc + unitPrice * number,
    0
  );

  return (
    <>
      <PageContainer
        header="受注詳細"
        action={
          <Button
            color="blue"
            content="一覧へ"
            onClick={() => {
              window.history.back();
            }}
          />
        }
      >
        <Segment>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>受注番号</Table.Cell>
                      <Table.Cell>
                        {order.takeoutDate.replaceAll("-", "")}-
                        {order.orderNumber}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>受注日時</Table.Cell>
                      <Table.Cell>{order.orderDateTimeRenderValue}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>受け渡し日時</Table.Cell>
                      <Table.Cell>{order.takeoutDateTimeRendeValue}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>決済方法</Table.Cell>
                      <Table.Cell>
                        {makePaymentTypeLabel(
                          order.paymentType,
                          order.settlement
                        )}
                        {order.paymentType === "store" && (
                          <div>¥{totalPrice}</div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>ステータス</Table.Cell>
                      <Table.Cell>
                        {makeStatusLabel(order.orderStatus)}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>注文者名</Table.Cell>
                      <Table.Cell>{order.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>注文者電話番号</Table.Cell>
                      <Table.Cell>{order.tel}</Table.Cell>
                    </Table.Row>
                    {order.hasShippingInformation() && (
                      <Table.Row>
                        <Table.Cell>配送先</Table.Cell>
                        <Table.Cell>
                          <div>〒{order.shippingZipCode}</div>
                          <div>{order.shippingAddress}</div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                {(order.orderStatus === "new" ||
                  order.orderStatus === "processing") && (
                  <>
                    <p>
                      ※こちらからのキャンセルの場合は、
                      注文者に連絡を取ってください
                    </p>
                    <div css={{ textAlign: "right" }}>
                      <Button color="red" onClick={cancel}>
                        キャンセル
                      </Button>
                    </div>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header>受注詳細</Header>

          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>商品コード</Table.HeaderCell>
                      <Table.HeaderCell>商品名</Table.HeaderCell>
                      <Table.HeaderCell>単価</Table.HeaderCell>
                      <Table.HeaderCell>数量</Table.HeaderCell>
                      <Table.HeaderCell>合計（税込み）</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {order.orderDetails.map(
                      ({ product, number, unitPrice }, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{product.code}</Table.Cell>
                          <Table.Cell>{product.name}</Table.Cell>
                          <Table.Cell>¥{unitPrice}</Table.Cell>
                          <Table.Cell>{number}</Table.Cell>
                          <Table.Cell>¥{unitPrice * number}</Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={6}>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>注文合計（税込み）</Table.Cell>
                      <Table.Cell>¥{totalPrice}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header>備考</Header>
          <p>{order.remarks}</p>
        </Segment>
      </PageContainer>
    </>
  );
};
