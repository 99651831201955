import { Type, Expose } from "class-transformer";

export default class Product {
  @Expose() id: number;
  @Expose() code: string;
  @Expose() name: string;
  @Expose() price: number;
  @Expose() display: boolean;
  @Expose({ name: "display_order" }) displayOrder: number;
  @Expose() description: string;
  @Expose() cautions: string;
  @Expose() image: string;
  @Expose({ name: "product_categories" })
  @Type(() => ProductCategory)
  productCategories: ProductCategory[];
}

export class ProductCategory {
  @Expose() id: number;
  @Expose() code: string;
  @Expose() name: string;
  @Expose() display: boolean;
  @Expose({ name: "display_order" }) displayOrder: number;
}
