import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { newsService } from "domain/service";

import Post from "domain/model/Post";

const NewsContainer = () => {
  const [news, setNews] = useState<Post[] | null>(null);
  const [editNews, setEditNews] = useState<string | null>(null);
  const [hasMoreNews, setHasMoreNews] = useState<boolean | null>(false);
  const [loading, setLoading] = useState(false);

  const fetchNews = useCallback(
    async (
      isAgency: boolean,
      storeId: string | undefined,
      createdAt?: string
    ) => {
      setLoading(true);
      const lastFetchedNews = await newsService
        .fetchAllNews(isAgency, storeId, createdAt)
        .then((fetchedNews) => {
          setNews((currentState) => {
            if (currentState === null) {
              return fetchedNews;
            } else {
              return [...currentState, ...fetchedNews];
            }
          });
          // 50件以下の場合は次のバージョンが50件あるかはチェックしない
          if (fetchedNews.length < 50) {
            return undefined;
          }
          return fetchedNews[fetchedNews.length - 1];
        });
      setLoading(false);
      //  次のバージョンが50件あるかチェック
      if (lastFetchedNews === undefined) {
        return;
      }
      newsService
        .fetchAllNews(isAgency, storeId, lastFetchedNews.createdAtStr)
        .then((fetchedNextNews) => {
          if (fetchedNextNews.length !== 0) {
            setHasMoreNews(true);
          } else {
            setHasMoreNews(false);
          }
        });
    },
    []
  );

  return {
    loading,
    news,
    editNews,
    fetchNews,
    setEditNews,
    hasMoreNews,
  };
};

export default createContainer(NewsContainer);
