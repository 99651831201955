import { Expose } from "class-transformer";

export const ReplyTypes = {
  EMAIL: "email",
  SMS: "sms",
} as const;

export type ReplyType = typeof ReplyTypes[keyof typeof ReplyTypes];

export default class FormDetails {
  @Expose() id: number;
  @Expose() name: string;
  @Expose({ name: "pub_email" }) email: string;
  @Expose({ name: "redirect_to" }) redirectUrl: string;
  @Expose({ name: "reply_type" }) replyType: ReplyType;
  @Expose({ name: "reply_subject" }) replySubject: string;
  @Expose({ name: "reply_message" }) replyMessage: string;
}
