export const FormTypes = {
  SINGLE_LINE_TEXT: "text",
  MULTIPLE_LINE_TEXT: "textarea",
  EMAIL: "email",
  SELECT: "selectbox",
  RADIO: "radio",
  DATE: "date",
  CHECKBOX: "checkbox",
} as const;

export type FormType = typeof FormTypes[keyof typeof FormTypes];

export type Form = {
  label: string;
  type: FormType;
  options: Array<string>;
  required: boolean;
};

export type FormContent = {
  formName: string;
  pubEmail: string;
  forms: Array<Form>;
  redirectUrl: string;
};

export type FormValues = {
  formContent: FormContent;
};
