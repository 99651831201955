import React from "react";
import { ProductForm } from "components/Product/ProductForm";
import { SubmitTypes } from "components/JobOffer/lib/JobOfferForm";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { productRepository } from "domain/repository";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

export const NewForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const history = useHistory();

  return (
    <ProductForm
      initialValues={{
        code: "",
        name: "",
        price: 0,
        display: true,
        displayOrder: 0,
        description: "",
        cautions: "",
        image: "",
        categoryIds: [],
      }}
      isAgency={isAgency}
      storeId={storeId}
      handleSubmit={({ values, setStatus, resetForm }) => {
        productRepository
          .createProduct(isAgency, storeId, values)
          .then(() => {
            resetForm();
            const productIndex = isAgency
              ? generatePath(routes.agency.productIndex.pathname, {
                  storeId,
                })
              : routes.store.productIndex;
            history.push(productIndex);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              setStatus({ success: "", error: e.response.data.message });
            } else {
              setStatus({ success: "", error: "更新に失敗しました" });
            }
          });
      }}
      submitType={SubmitTypes.UPDATE}
    />
  );
};
