import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PageContainer from "components/lib/PageContainer";
import { UserContainer } from "containers";
import { articleService } from "domain/service";
import { articleRepository } from "domain/repository";

import Category from "domain/model/Category";

import { StoreIdParam } from "config/raven";
import { Confirm, Message } from "semantic-ui-react";
import { AddForm } from "components/lib/subcategory/AddForm";
import { CategoryList } from "components/lib/subcategory/CategoryList";
import { useMessage } from "hooks/useMessage";
import { RavenLoader } from "components/lib/RavenLoader";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const Container: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const [categories, setCategories] = useState<Category[] | null>(null);
  const [editCategoryValue, setEditCategoryValue] = useState<string | null>(
    null
  );
  const [
    deleteRequestedCategoryValue,
    setDeleteRequestedCategoryValue,
  ] = useState<string | null>(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { renderMessage, setSuccessMessage, setErrorMessage } = useMessage({
    messageSize: "tiny",
  });
  const [forRendering, setForRendering] = useState({});
  const [deleting, setDeleting] = useState(false);

  const handleUpdateCategory = (
    updateCategoryValue: string,
    updateCategoryName: string
  ) => {
    return articleRepository
      .updateCategory(
        isAgency,
        storeId,
        updateCategoryValue,
        updateCategoryName
      )
      .then(() => {
        setForRendering({});
        setSuccessMessage("カテゴリーが更新されました");
      })
      .catch(() => {
        setErrorMessage("カテゴリーの更新に失敗しました");
      })
      .finally(() => {
        setEditCategoryValue(null);
      });
  };

  const handleDeleteCategoryButton = (deleteCategoryValue: string) => {
    setOpenDeleteModal(true);
    setDeleteRequestedCategoryValue(deleteCategoryValue);
  };

  const handleDeleteCategory = () => {
    if (deleteRequestedCategoryValue === null) {
      return;
    }
    setDeleting(true);
    articleRepository
      .deleteCategory(isAgency, storeId, deleteRequestedCategoryValue)
      .then(() => {
        setForRendering({});
        setSuccessMessage("カテゴリーが削除されました");
      })
      .catch(() => {
        setErrorMessage("カテゴリーの削除に失敗しました");
      })
      .finally(() => {
        setDeleteRequestedCategoryValue(null);
        setOpenDeleteModal(false);
        setDeleting(false);
      });
  };

  useEffect(() => {
    const fetchCategories = () => {
      articleService.fetchCategory(isAgency, storeId).then((res) => {
        setCategories(res);
      });
    };
    fetchCategories();
  }, [isAgency, storeId, forRendering]);

  const AddFormComponent = () => (
    <AddForm
      handleAdd={(categoryName) =>
        articleRepository
          .addArticleCategory(isAgency, storeId, categoryName)
          .then(() => {
            setForRendering({});
            setSuccessMessage("カテゴリーが追加されました");
          })
          .catch(() => {
            setErrorMessage("カテゴリーの追加に失敗しました");
          })
      }
    />
  );

  if (categories === null) {
    return <RavenLoader active />;
  } else if (categories.length === 0) {
    return (
      <>
        <Message size="tiny" content="カテゴリがありません" />
        <AddFormComponent />
      </>
    );
  } else {
    return (
      <>
        <RavenLoader active={deleting} content="削除中" />
        <Confirm
          open={openDeleteModal}
          content="カテゴリーを削除してもよろしいですか？"
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={handleDeleteCategory}
        />
        {renderMessage()}
        <AddFormComponent />
        <CategoryList
          categories={categories}
          editCategoryValue={editCategoryValue}
          setEditCategoryValue={setEditCategoryValue}
          handleUpdateCategory={handleUpdateCategory}
          handleDeleteCategoryButton={handleDeleteCategoryButton}
        />
      </>
    );
  }
};

export const ArticleCategory: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  return (
    <PageContainer
      header="記事カテゴリ一覧"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .articlePage()
        .build("記事カテゴリ一覧")}
    >
      <Container />
    </PageContainer>
  );
};
