import React, { useState } from "react";
import { Button, Modal, Message } from "semantic-ui-react";
import { jobOfferRepository } from "domain/repository";
import { useHistory, generatePath } from "react-router-dom";
import routes from "utils/routes";
import { RavenLoader } from "components/lib/RavenLoader";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  formId: string;
};
export const FormDelete: React.FC<Props> = ({ isAgency, storeId, formId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [deleting, setDeleting] = useState(false);

  const history = useHistory();

  const handleDelete = () => {
    setDeleting(true);
    jobOfferRepository
      .deleteForm(isAgency, storeId, formId)
      .then(() => {
        const jobOfferFormIndexUrl = isAgency
          ? generatePath(routes.agency.jobOfferFormIndex.pathname, {
              storeId,
            })
          : routes.store.jobOfferFormIndex.pathname;

        history.push(jobOfferFormIndexUrl);
      })
      .catch(() => {
        setErrorMessage("削除に失敗しました");
      })
      .finally(() => {
        setDeleting(false);
        setOpenModal(false);
      });
  };

  if (errorMessage) {
    return <Message error content={errorMessage} />;
  }

  return (
    <>
      <RavenLoader active={deleting} content="削除中" />
      <Modal
        open={openModal}
        trigger={
          <Button
            css={{
              display: "block !important",
              width: "140px",
              margin: "0 0 0 auto !important",
            }}
            negative
            content="求人票を削除"
            onClick={() => setOpenModal(true)}
          />
        }
      >
        <Modal.Header content="求人票を削除" />
        <Modal.Content content="求人票を削除してよろしいですか？" />
        <Modal.Actions>
          <Button
            content="キャンセル"
            onClick={() => setOpenModal(false)}
          ></Button>
          <Button color="red" content="削除" onClick={handleDelete}></Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
