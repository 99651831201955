import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import JobFeedItem from "components/JobFeed/Index/JobFeedItem";
import routes from "utils/routes";

import JobFeed from "domain/model/JobFeed";
import { Message, Table } from "semantic-ui-react";

type Props = {
  jobFeeds: JobFeed[];
  isAgency: boolean;
  storeId: string | undefined;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>募集職種</Table.HeaderCell>
        <Table.HeaderCell>会社名</Table.HeaderCell>
        <Table.HeaderCell>雇用形態/採用区分</Table.HeaderCell>
        <Table.HeaderCell>勤務地</Table.HeaderCell>
        <Table.HeaderCell>給料</Table.HeaderCell>
        <Table.HeaderCell>{/* 編集ボタン */}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const JobFeedList: React.FC<Props> = ({
  isAgency,
  jobFeeds,
  storeId,
}) => {
  const history = useHistory();

  if (jobFeeds.length === 0) {
    return <Message content="求人フィードの情報はありません" />;
  }

  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {jobFeeds.map((jobFeed, i) => {
            const handleEdit = () => {
              const editUrl = isAgency
                ? generatePath(routes.agency.jobFeedEdit.pathname, {
                    storeId,
                    id: jobFeed.id,
                  })
                : generatePath(routes.store.jobFeedEdit.pathname, {
                    id: jobFeed.id,
                  });
              history.push(editUrl);
            };
            return (
              <JobFeedItem
                jobFeed={jobFeed}
                handleClickEdit={handleEdit}
                index={i}
                key={jobFeed.id}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default JobFeedList;
