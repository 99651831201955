import React from "react";

import { Segment } from "semantic-ui-react";
import MenuItem from "components/lib/MenuItem";
import { useHistory, generatePath } from "react-router-dom";
import routes from "utils/routes";

type Props = { storeId: string | undefined };
const DomainRegisterMenu: React.FC<Props> = ({ storeId }) => {
  const history = useHistory();
  return (
    <Segment>
      <MenuItem
        header="ドメインを取得する"
        onClick={() => {
          history.push(
            generatePath(routes.agency.domainRegist.pathname, {
              storeId,
            })
          );
        }}
      />
      <MenuItem
        header="外部で取得済みのドメインを登録する"
        onClick={() => {
          history.push(
            generatePath(routes.agency.externalDomain.pathname, {
              storeId,
            })
          );
        }}
      />
    </Segment>
  );
};

export default DomainRegisterMenu;
