import { Expose } from "class-transformer";

export type JobType =
  | "full_time" // 正社員
  | "part_time" // パート・アルバイト
  | "contractor" // 契約社員
  | "temporary" // 一時的な雇用
  | "intern" // インターンシップ
  | "volunteer" // ボランティア
  | "per_diem" // 日雇い
  | "other"; // その他

export type SalaryType =
  | "hour" // 時間給
  | "day" // 日給
  | "week" // 週給
  | "month" // 月給
  | "year"; // 年給・年俸

export default class JobFeed {
  @Expose() id: number;
  @Expose() url: string;
  @Expose() title: string;
  @Expose() company: string;
  @Expose({ name: "job_type" }) jobType: JobType;
  @Expose() description: string;
  @Expose({ name: "post_date" }) postDate: Date;
  @Expose({ name: "salary_type" }) salaryType: SalaryType;
  @Expose({ name: "salary_min" }) salaryMin: number;
  @Expose({ name: "salary_max" }) salaryMax: number;
  @Expose() state: string | null;
  @Expose() city: string | null;
  @Expose() address: string | null;
  @Expose() station: string | null;
  @Expose() experience: string | null;
  @Expose({ name: "working_time" }) workingTime: string | null;
  @Expose({ name: "image_url" }) imageUrl: string | null;

  jobTypeText(): string {
    switch (this.jobType) {
      case "full_time":
        return "正社員";
      case "part_time":
        return "パート・アルバイト";
      case "contractor":
        return "契約社員";
      case "temporary":
        return "一時的な雇用";
      case "intern":
        return "インターンシップ";
      case "volunteer":
        return "ボランティア";
      case "per_diem":
        return "日雇い";
      case "other":
        return "その他";
    }
  }

  salaryTypeText(): string {
    switch (this.salaryType) {
      case "hour":
        return "時給";
      case "day":
        return "日給";
      case "week":
        return "週給";
      case "month":
        return "月給";
      case "year":
        return "年俸";
    }
  }

  salaryText(): string {
    return this.salaryMin >= 10000
      ? this.salaryTypeText() +
          this.salaryMin / 10000 +
          "〜" +
          this.salaryMax / 10000 +
          "万円"
      : this.salaryTypeText() + this.salaryMin + "〜" + this.salaryMax + "円";
  }
}
