import React, { useState, useEffect } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import {
  JobOfferForm,
  SubmitTypes,
} from "components/JobOffer/lib/JobOfferForm";
import FormDetails from "domain/model/JobOffer/FormDetails";
import { jobOfferService } from "domain/service";
import { jobOfferRepository } from "domain/repository";
import routes from "utils/routes";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  formId: string;
};

export const EditForm: React.FC<Props> = ({ isAgency, storeId, formId }) => {
  const [formDetails, setFormDetails] = useState<FormDetails | null>(null);

  useEffect(() => {
    jobOfferService
      .fetchFormDetails({ isAgency, storeId, formId })
      .then((form) => setFormDetails(form));
  }, [formId, isAgency, storeId]);

  const history = useHistory();

  if (formDetails === null) {
    return <Loader active />;
  }

  const {
    name,
    email,
    redirectUrl,
    replyType,
    replySubject,
    replyMessage,
  } = formDetails;

  return (
    <JobOfferForm
      initialValues={{
        name,
        email,
        redirectUrl,
        replyType,
        replySubject,
        replyMessage,
      }}
      handleSubmit={({ values, setStatus, resetForm }) => {
        jobOfferRepository
          .updateForm(
            isAgency,
            storeId,
            {
              name: values.name,
              email: values.email,
              redirectUrl: values.redirectUrl,
              replyType: values.replyType,
              replySubject: values.replySubject,
              replyMessage: values.replyMessage,
            },
            formId
          )
          .then(() => {
            resetForm();
            const jobOfferFormIndexUrl = isAgency
              ? generatePath(routes.agency.jobOfferFormIndex.pathname, {
                  storeId,
                })
              : routes.store.jobOfferFormIndex.pathname;

            history.push(jobOfferFormIndexUrl);
          })
          .catch(() => {
            setStatus({ success: "", error: "更新に失敗しました" });
          });
      }}
      submitType={SubmitTypes.UPDATE}
    />
  );
};
