import React, { useEffect, useState } from "react";
import AwsResource from "domain/model/AwsResource";
import { Header, Segment, Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { domainService } from "domain/service";
import { ExternalDnsRecordset } from "domain/model/ExternalDnsRecordset";
import { DnsRecordsetTable } from "components/SiteManage/Content/Domain/DomainInformation/DnsRecordsetTable";

type Props = { awsResource: AwsResource };
export const ExternalDnsRecordSet: React.FC<Props> = () => {
  const { storeId } = useParams<StoreIdParam>();
  const [recordSet, setRecordSet] = useState<ExternalDnsRecordset | null>(null);
  useEffect(() => {
    if (storeId === undefined) {
      return;
    }
    domainService.fetchExternalDnsRecordset(storeId).then((recordSet) => {
      setRecordSet(recordSet);
    });
  }, [storeId]);

  return (
    <Segment>
      <Header content="DNSレコード情報" />
      {recordSet === null ? (
        <Loader active />
      ) : (
        <DnsRecordsetTable recordSet={recordSet} />
      )}
    </Segment>
  );
};
