import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Form, Radio } from "semantic-ui-react";
import Store from "domain/model/Store";

type FormValues = {
  title: string;
  body: string;
  active: boolean;
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  body: Yup.string().required(),
  active: Yup.boolean(),
});

type Props = {
  initialValues: FormValues;
  currentStore: Store;
  handleSubmit: ({
    values,
    resetForm,
  }: {
    values: FormValues;
    resetForm: () => void;
  }) => void;
};

const TakeoutMessageTemplateForm: React.FC<Props> = ({
  initialValues,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      handleSubmit({ values, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Field required>
        <label>タイトル</label>
        <Form.Input
          name="title"
          error={formik.errors.title}
          value={formik.values.title}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>本文</label>
        <Form.TextArea
          name="body"
          error={formik.errors.body}
          value={formik.values.body}
          onChange={formik.handleChange}
          rows={25}
        />
      </Form.Field>

      <ToggleWrapper>
        <ToggleLabel>無効</ToggleLabel>
        <Radio
          toggle
          css={{ margin: "0 0.5em" }}
          id="active"
          name="active"
          error={formik.errors.active}
          checked={formik.values.active}
          onClick={() => {
            formik.setFieldValue("active", !formik.values.active);
          }}
        />
        <ToggleLabel>有効</ToggleLabel>
      </ToggleWrapper>

      <div style={{ textAlign: "center" }}>
        <Button color="blue" content="保存" type="submit" />
      </div>
    </Form>
  );
};

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
`;

const ToggleLabel = styled.div`
  font-weight: bolder;
`;

export default TakeoutMessageTemplateForm;
