import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { SelectMediaModal } from "components/lib/SelectMediaModal";

type Props = {
  data: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  mediaUrls?: string[];
  refetchMediaUrls?: () => void;
};

const toolbar =
  "undo redo | formatselect | fontsizeselect | bold italic underline strikethrough | forecolor backcolor | image link | fullscreen | code ";

const plugins = ["fullscreen", "link", "table", "lists", "contextmenu"];

// TODO: TinyMCEのモーダルとsemantic-uiのモーダルのz-indexを調整するために、本当はしたくないが、index.cssでスタイルを上書きしている。
const DocumentWysiwygEditorWithMedia: React.FC<
  Props & {
    mediaUrls: string[];
    refetchMediaUrls: () => void;
  }
> = ({ data, onChange, readOnly, mediaUrls, refetchMediaUrls }) => {
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [mediaCallback, setMediaCallback] = useState<{
    callback: Function;
  } | null>(null);
  return (
    <>
      <SelectMediaModal
        title="画像を選択"
        open={openMediaModal}
        mediaUrls={mediaUrls}
        onUploaded={refetchMediaUrls}
        handleMediaClick={(url) => {
          if (mediaCallback !== null) {
            mediaCallback.callback(url);
            setOpenMediaModal(false);
          }
        }}
        close={() => setOpenMediaModal(false)}
      />
      <Editor
        initialValue={data}
        apiKey={process.env.REACT_APP_TYNY_MCE_API_KEY}
        init={{
          height: 800,
          menubar: false,
          plugins: [...plugins, "image", "code"],
          toolbar: readOnly ? "" : toolbar,
          language: "ja",
          branding: false,
          contextmenu:
            "link image inserttable cut copy paste | cell row column deletetable",
          file_picker_callback: function (
            callback: Function,
            value: string,
            meta: Record<string, any>
          ) {
            if (meta.filetype === "image") {
              setMediaCallback({ callback });
              setOpenMediaModal(true);
            }
          },
          image_caption: true,
        }}
        disabled={readOnly}
        onEditorChange={onChange}
      />
    </>
  );
};

const DocumentWysiwygEditorBasic: React.FC<Omit<Props, "mediaUrls">> = ({
  data,
  onChange,
  readOnly,
}) => {
  return (
    <Editor
      initialValue={data}
      apiKey={process.env.REACT_APP_TYNY_MCE_API_KEY}
      init={{
        height: 800,
        menubar: false,
        plugins: plugins,
        toolbar: readOnly ? "" : toolbar,
        language: "ja",
        branding: false,
      }}
      disabled={readOnly}
      onEditorChange={onChange}
    />
  );
};

export const DocumentWysiwygEditor: React.FC<Props> = ({
  data,
  onChange,
  readOnly,
  mediaUrls,
  refetchMediaUrls,
}) => {
  if (refetchMediaUrls) {
    return (
      <DocumentWysiwygEditorWithMedia
        data={data}
        onChange={onChange}
        readOnly={readOnly}
        mediaUrls={mediaUrls ?? []}
        refetchMediaUrls={refetchMediaUrls}
      />
    );
  }

  return (
    <DocumentWysiwygEditorBasic
      data={data}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
};
