import api from "utils/api";
import endpoint from "utils/endpoint";
import { JobType, SalaryType } from "domain/model/JobFeed";

class JobFeedRepository {
  create(
    isAgency: boolean,
    storeId: string | undefined,
    {
      url,
      title,
      company,
      jobType,
      description,
      postDate,
      salaryType,
      salaryMin,
      salaryMax,
      state,
      city,
      address,
      station,
      experience,
      workingTime,
      imageUrl,
    }: {
      url: string;
      title: string;
      company: string;
      jobType: JobType;
      description: string;
      postDate: Date;
      salaryType: SalaryType;
      salaryMin: number;
      salaryMax: number;
      state: string | null;
      city: string | null;
      address: string | null;
      station: string | null;
      experience: string | null;
      workingTime: string | null;
      imageUrl: string | null;
    }
  ) {
    const rqUrl =
      isAgency && storeId
        ? endpoint.agency.jobFeed.feeds(storeId)
        : endpoint.store.jobFeed.feeds();

    const body = {
      job_feed: {
        url: url,
        title: title,
        company: company,
        job_type: jobType,
        description: description,
        post_date: postDate,
        salary_type: salaryType,
        salary_min: salaryMin,
        salary_max: salaryMax,
        state: state === "" ? null : state,
        city: city === "" ? null : city,
        address: address === "" ? null : address,
        station: station === "" ? null : station,
        experience: experience === "" ? null : experience,
        working_time: workingTime === "" ? null : workingTime,
        image_url: imageUrl === "" ? null : imageUrl,
      },
    };
    return api.post(rqUrl, body).then((res) => res.data);
  }

  update(
    isAgency: boolean,
    storeId: string | undefined,
    feedId: string,
    {
      url,
      title,
      company,
      jobType,
      description,
      postDate,
      salaryType,
      salaryMin,
      salaryMax,
      state,
      city,
      address,
      station,
      experience,
      workingTime,
      imageUrl,
    }: {
      url: string;
      title: string;
      company: string;
      jobType: JobType;
      description: string;
      postDate: Date;
      salaryType: SalaryType;
      salaryMin: number;
      salaryMax: number;
      state: string | null;
      city: string | null;
      address: string | null;
      station: string | null;
      experience: string | null;
      workingTime: string | null;
      imageUrl: string | null;
    }
  ) {
    const rqUrl =
      isAgency && storeId
        ? endpoint.agency.jobFeed.feed(storeId, feedId)
        : endpoint.store.jobFeed.feed(feedId);
    const body = {
      job_feed: {
        url: url,
        title: title,
        company: company,
        job_type: jobType,
        description: description,
        post_date: postDate,
        salary_type: salaryType,
        salary_min: salaryMin,
        salary_max: salaryMax,
        state: state === "" ? null : state,
        city: city === "" ? null : city,
        address: address === "" ? null : address,
        station: station === "" ? null : station,
        experience: experience === "" ? null : experience,
        working_time: workingTime === "" ? null : workingTime,
        image_url: imageUrl === "" ? null : imageUrl,
      },
    };
    return api.put(rqUrl, body).then((res) => res.data.job_feed);
  }

  delete(isAgency: boolean, storeId: string | undefined, formId: string) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobFeed.feed(storeId, formId)
        : endpoint.store.jobFeed.feed(formId);
    return api.delete(url).then((res) => res.data);
  }
}

export default new JobFeedRepository();
