import React from "react";
import { VersionData } from "domain/model/Version";
import { Button, Popup, Table } from "semantic-ui-react";
import dayjs from "dayjs";

type Props = {
  versionData: VersionData;
  index: number;
  titleEditInputOrValue: string | React.ReactElement;
  isRollbackLoading: boolean;
  editVersion: string | null;
  headVersion: string | undefined;
  handleEditButton: () => void;
  handleClickVersionShow: () => void;
  handleClickDownload: () => void;
  handleClickPreview: () => void;
  handleClickRollback: () => void;
  isMobile: boolean;
};
export const VersionListItem: React.FC<Props> = ({
  versionData,
  index,
  headVersion,
  titleEditInputOrValue,
  handleEditButton,
  handleClickVersionShow,
  handleClickDownload,
  handleClickPreview,
  handleClickRollback,
  isRollbackLoading,
  isMobile,
}) => (
  <Table.Row>
    <Table.Cell>{index + 1}</Table.Cell>
    <Table.Cell>{titleEditInputOrValue}</Table.Cell>
    <Table.Cell>
      {isMobile ? (
        <div css={{ flexDirection: "column" }}>
          <Button
            content="タイトルを変更"
            onClick={handleEditButton}
            size="mini"
            css={{ margin: "4px!important" }}
          />
        </div>
      ) : (
        <div css={{ justifyContent: "flex-end" }}>
          <Popup
            content="タイトルを変更"
            inverted
            trigger={<Button icon="edit" onClick={handleEditButton} />}
          />
        </div>
      )}
    </Table.Cell>
    <Table.Cell>
      {dayjs(Number(versionData.createdAt) * 1000).format("YYYY/MM/DD HH:mm")}
    </Table.Cell>
    <Table.Cell>{versionData.metadata.committedBy}</Table.Cell>
    <Table.Cell>
      {isMobile ? (
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Button
            content="ソースツリー"
            onClick={handleClickVersionShow}
            size="mini"
            css={{ margin: "4px!important" }}
          />
          <Button
            content="zipファイルダウンロード"
            onClick={handleClickDownload}
            size="mini"
            css={{ margin: "4px!important" }}
          />
          <Button
            content="プレビュー"
            onClick={handleClickPreview}
            size="mini"
            css={{ margin: "4px!important" }}
          />
        </div>
      ) : (
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Popup
            content="ソースツリー"
            inverted
            trigger={<Button icon="list" onClick={handleClickVersionShow} />}
          />
          <Popup
            content="zipファイルダウンロード"
            inverted
            trigger={<Button icon="download" onClick={handleClickDownload} />}
          />
          <Popup
            content="プレビュー"
            inverted
            trigger={<Button icon="globe" onClick={handleClickPreview} />}
          />
        </div>
      )}
    </Table.Cell>
    <Table.Cell>
      {headVersion === versionData.versionHash ? (
        <Button content="公開中" size="mini" color="red" as="div" fluid />
      ) : (
        <Button
          content="公開する"
          size="mini"
          color="blue"
          fluid
          loading={isRollbackLoading}
          onClick={handleClickRollback}
        />
      )}
    </Table.Cell>
  </Table.Row>
);
