import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory, useRouteMatch, generatePath } from "react-router-dom";
import { Image, Loader } from "semantic-ui-react";
import { UserContainer, MetaContainer } from "containers";
import routes from "utils/routes";
import SignInForm from "components/SignIn/SignInForm";
import COLOR from "config/color.json";

const SignIn = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const metaContainer = MetaContainer.useContainer();
  const userContainer = UserContainer.useContainer();
  const { currentUser, userType, userToken } = userContainer;

  const history = useHistory();
  const { path } = useRouteMatch();

  const redirectUrl =
    userType === "agency"
      ? generatePath(routes.adminAgency.storeIndex.pathname, { page: 1 })
      : routes.store.dashboard.pathname;

  useEffect(() => {
    currentUser && history.push(redirectUrl);
    // eslint-disable-next-line
  }, [history, currentUser, userType]);

  if (userToken) {
    return <Loader active></Loader>;
  }

  return (
    <>
      <div
        css={{
          height: "inherit",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div css={{ width: isMobile ? "90%" : "35%" }}>
          <Image
            src={metaContainer.logo}
            size="small"
            style={{ margin: "0 auto" }}
          />
          <p css={{ textAlign: "center", color: COLOR.SUB2 }}>
            バージョン管理と直感的な操作でサイト制作・保守・拡張。
          </p>
          <div>
            <SignInForm
              signIn={userContainer.signIn}
              userType={path.match(/agency/) ? "agency" : "store"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
