import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import MESSAGE from "config/message.json";
import { domainService } from "domain/service";
import { Button, Form, Label, Loader, Message } from "semantic-ui-react";

type Props = {
  toNextStep: (targetDomain: string) => void;
};
export const CheckDomainContainer: React.FC<Props> = ({ toNextStep }) => {
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const [targetDomain, setTargetDomain] = useState<string | null>(null);
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      domain: "",
    },
    validationSchema: Yup.object({
      domain: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
    }),
    onSubmit: ({ domain }, formikHelpers) => {
      domainService
        .checkAvailability(domain)
        .then((isAavailable) => {
          setIsAvailable(isAavailable);
          setTargetDomain(domain);
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const AvailabelContent = () => {
    if (isAvailable === null) {
      return null;
    }
    if (isAvailable && targetDomain !== null) {
      return (
        <>
          <label>{`「${targetDomain}」は使用することができます。`}</label>
          <div css={{ textAlign: "right" }}>
            <Button
              primary
              content="ドメイン取得に進む"
              icon="right arrow"
              labelPosition="right"
              onClick={() => toNextStep(targetDomain)}
            />
          </div>
        </>
      );
    }

    return <Label content={`「${targetDomain}」は利用できません`} />;
  };
  return (
    <>
      <Message
        header="取得したいドメインを入力してください"
        content={
          <p>
            .com、.net、.biz、.in、.info のドメインを取得できます。
            <br />
            <small>
              ※.jp、.co.jp のドメインご希望の方は別途ご相談ください。
            </small>
          </p>
        }
      />
      <Form onSubmit={handleSubmit}>
        <Form.Group inline>
          <Form.Input
            name="domain"
            placeholder="example.com"
            error={errors.domain}
            value={values.domain}
            onChange={handleChange}
            width={8}
          />
          <Button primary content="検索" type="submit" />
        </Form.Group>
      </Form>
      <AvailabelContent />
      {isSubmitting ? <Loader active content="確認中" /> : null}
    </>
  );
};
