import React from "react";
import { Table, Button } from "semantic-ui-react";
import JobFeed from "domain/model/JobFeed";

type Props = {
  jobFeed: JobFeed;
  handleClickEdit: () => void;
  index: number;
};

const JobFeedItem: React.FC<Props> = ({ jobFeed, handleClickEdit, index }) => {
  return (
    <Table.Row key={index}>
      <Table.Cell>{jobFeed.title}</Table.Cell>
      <Table.Cell>{jobFeed.company}</Table.Cell>
      <Table.Cell>{jobFeed.jobTypeText()}</Table.Cell>
      <Table.Cell>
        {jobFeed.state}
        {jobFeed.city}
      </Table.Cell>
      <Table.Cell>{jobFeed.salaryText()}</Table.Cell>
      <Table.Cell>
        <Button
          icon="edit"
          onClick={handleClickEdit}
          color="blue"
          content="編集"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default JobFeedItem;
