import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Segment, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { StoreContainer, UserContainer } from "containers";
import { productRepository } from "domain/repository";
import FileUploadResult from "domain/model/FileUploadResult";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

type UploadEvent = "uploadProductFile" | "uploadProductCategoryFile";
type UploadResult = FileUploadResult & { uploadEvent: UploadEvent };

export const ProductImport = () => {
  const userContainer = UserContainer.useContainer();
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null);
  const [currentEvent, setCurrentEvent] = useState<UploadEvent | null>(null);
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;
  const makeOnChange = (uploadEvent: UploadEvent) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    if (!event.target?.files) return;
    setCurrentEvent(uploadEvent);
    setUploadResult(null);

    const result = await productRepository[uploadEvent](
      isAgency,
      storeId,
      event.target.files[0]
    ).catch(() => {
      setUploadResult({
        uploadEvent,
        error: "インポートに失敗しました。ファイル形式をご確認ください。",
      });
      setCurrentEvent(null);
      return null;
    });

    if (!result) {
      return;
    }

    event.target.files = null;

    setUploadResult({ uploadEvent, ...result });
    setCurrentEvent(null);
  };

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  return (
    <PageContainer
      header="商品インポート"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .productPage()
        .build("商品インポート")}
    >
      <Segment>
        <p>
          更新対象の商品データを含むCSVファイルをアップロードして、商品を更新出来ます。
          CSVファイルに含まれていない商品については、削除されず無視されます。
          商品を削除したい場合は、「削除済みフラグ」にTRUEを指定してください。
        </p>
        {uploadResult && uploadResult.uploadEvent === "uploadProductFile" && (
          <UploadResultMessage uploadResult={uploadResult} />
        )}
        <InputButton
          onChange={makeOnChange("uploadProductFile")}
          disabled={!!currentEvent && currentEvent !== "uploadProductFile"}
          loading={currentEvent === "uploadProductFile"}
        >
          商品データをインポート
        </InputButton>
      </Segment>

      <Segment>
        <p>
          全てのカテゴリーデータを含むCSVファイルをアップロードして、カテゴリーを更新出来ます。
          CSVファイルに含まれていないカテゴリーについては削除し、該当カテゴリーと商品の紐付けも削除されます。
        </p>
        {uploadResult &&
          uploadResult.uploadEvent === "uploadProductCategoryFile" && (
            <UploadResultMessage uploadResult={uploadResult} />
          )}
        <InputButton
          onChange={makeOnChange("uploadProductCategoryFile")}
          disabled={
            !!currentEvent && currentEvent !== "uploadProductCategoryFile"
          }
          loading={currentEvent === "uploadProductCategoryFile"}
        >
          カテゴリーデータをインポート
        </InputButton>
      </Segment>
    </PageContainer>
  );
};

type InputButtonType = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  loading: boolean;
};

const InputButton: React.FC<InputButtonType> = ({
  children,
  loading,
  disabled,
  onChange,
}) => (
  <Button
    color="blue"
    css={{ position: "relative" }}
    loading={loading}
    disabled={disabled}
  >
    {children}
    <input
      type="file"
      css={{
        position: "absolute",
        display: "block",
        width: "100%",
        opacity: 0,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
      onChange={onChange}
    />
  </Button>
);

const UploadResultMessage: React.FC<{
  uploadResult: UploadResult;
}> = ({ uploadResult }) => {
  if (uploadResult.error) {
    return (
      <div css={{ color: "#e66", fontWeight: "bolder", marginBottom: "16px" }}>
        {uploadResult.error}
      </div>
    );
  }

  return (
    <div css={{ lineHeight: "1.8em", marginBottom: "16px" }}>
      <b>インポートに成功しました。</b>
      <br />
      作成: {uploadResult.createdCount}件
      <br />
      更新: {uploadResult.updatedCount}件
      <br />
      削除: {uploadResult.deletedCount}件
      <br />
      合計: {uploadResult.totalCount}件
    </div>
  );
};
