import React, { useEffect } from "react";
import { VersionContainer, StoreContainer } from "containers";
import { Button, Loader, Segment, Message } from "semantic-ui-react";
import ProgressBar from "components/lib/ProgressBar";
import { VersionList } from "components/Version/Index/VersionList";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

const warningMessages = [
  <span>
    ファイルのダウンロードができない場合は、下記フォームよりご連絡ください。代行してファイルのダウンロード対応いたします。
    <a href="https://tayori.com/form/20113a6393d32e70f1ac719ae2a30637d65b2daa">
      https://tayori.com/form/20113a6393d32e70f1ac719ae2a30637d65b2daa
    </a>
  </span>,
];

export const Container: React.FC<Props> = ({ isAgency, storeId }) => {
  const storeContainer = StoreContainer.useContainer();
  const { currentStore } = storeContainer;
  const versionContainer = VersionContainer.useContainer();
  const {
    versions,
    fetchVersions,
    hasMoreVersions,
    loading,
    downloadStatus,
  } = versionContainer;

  useEffect(() => {
    fetchVersions(isAgency, storeId);
  }, [fetchVersions, isAgency, storeId]);

  if (versions === null) {
    return <Loader content="バージョン情報取得中" active />;
  }

  const registered = currentStore?.awsResource.domain !== null;

  return (
    <Segment>
      <Loader active={loading} size="large" />
      {registered ? null : (
        <Message
          negative
          content="サイトを公開するにはドメインの設定が必要です"
        />
      )}
      <ProgressBar status={downloadStatus} />
      <ul style={{ marginLeft: "1.5rem" }}>
        {warningMessages.map((message, i) => (
          <li key={i}>{message}</li>
        ))}
      </ul>
      <VersionList versions={versions} isAgency={isAgency} storeId={storeId} />
      {hasMoreVersions ? (
        <Button
          content="続きを読み込む"
          fluid
          onClick={() => {
            if (versions === null) {
              return;
            }
            fetchVersions(
              isAgency,
              storeId,
              versions[versions.length - 1].data.createdAt
            );
          }}
        />
      ) : null}
    </Segment>
  );
};
