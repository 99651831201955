import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { productService } from "domain/service";
import Product from "domain/model/Product";
import { ProductForm } from "components/Product/ProductForm";
import { SubmitTypes } from "components/JobOffer/lib/JobOfferForm";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { productRepository } from "domain/repository";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  id: string;
};

export const EditForm: React.FC<Props> = ({ isAgency, storeId, id }) => {
  const [product, setProduct] = useState<Product | null>(null);
  const history = useHistory();

  useEffect(() => {
    productService
      .fetchProduct(isAgency, storeId, id)
      .then((res) => setProduct(res));
  }, [id, isAgency, storeId]);

  if (product === null) {
    return <Loader active />;
  }

  const {
    code,
    name,
    price,
    display,
    displayOrder,
    description,
    cautions,
    image,
  } = product;

  return (
    <ProductForm
      initialValues={{
        code,
        name,
        price,
        display,
        displayOrder,
        description,
        cautions,
        image,
        categoryIds: product.productCategories.map(({ id }) => id),
      }}
      isAgency={isAgency}
      storeId={storeId}
      handleSubmit={({ values, setStatus, resetForm }) => {
        productRepository
          .updateProduct(isAgency, storeId, id, values)
          .then(() => {
            resetForm();
            const productIndex = isAgency
              ? generatePath(routes.agency.productIndex.pathname, {
                  storeId,
                })
              : routes.store.productIndex;
            history.push(productIndex);
          })
          .catch((e) => {
            if (e.response.status == 422) {
              setStatus({ success: "", error: e.response.data.message });
            } else {
              setStatus({ success: "", error: "更新に失敗しました" });
            }
          });
      }}
      submitType={SubmitTypes.UPDATE}
    />
  );
};
