import React, { useEffect } from "react";
import { ArticleContainer, StoreContainer } from "containers";
import { Button, Loader, Segment, Message } from "semantic-ui-react";
import { ArticleList } from "components/Post/Article/Index/ArticleList";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

export const Container: React.FC<Props> = ({ isAgency, storeId }) => {
  const storeContainer = StoreContainer.useContainer();
  const { currentStore } = storeContainer;
  const articleContainer = ArticleContainer.useContainer();
  const {
    articles,
    fetchArticles,
    hasMoreArticles,
    loading,
  } = articleContainer;

  useEffect(() => {
    fetchArticles(isAgency, storeId);
  }, [fetchArticles, isAgency, storeId]);

  if (articles === null) {
    return <Loader content="記事一覧取得中" active />;
  }

  const registered = currentStore?.awsResource.domain !== null;

  return (
    <Segment>
      <Loader active={loading} size="large" />
      {registered ? null : (
        <Message
          negative
          content="サイトを公開するにはドメインの設定が必要です"
        />
      )}
      <ArticleList articles={articles} isAgency={isAgency} storeId={storeId} />
      {hasMoreArticles ? (
        <Button
          content="続きを読み込む"
          fluid
          onClick={() => {
            if (articles === null) {
              return;
            }
            fetchArticles(
              isAgency,
              storeId,
              articles[articles.length - 1].createdAtStr
            );
          }}
        />
      ) : null}
    </Segment>
  );
};
