import React, { useState } from "react";
import { Form, Segment, Header, Message, Loader } from "semantic-ui-react";
import OptionStatus from "components/lib/OptionStatus";
import OptionTakeoutStatus from "components/lib/OptionTakeoutStatus";
import {
  sitestRepository,
  recruitRepository,
  tabelogRepository,
  takeoutRepository,
  externalTakeoutRepository,
} from "domain/repository";
import { AGENCY_OPTION } from "config/raven";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

type Props = {
  storeId: number;
  isAgency: boolean;
  moveDashboard: (storeId: number | undefined) => void;
};

const OptionForm: React.FC<Props> = ({ storeId, isAgency, moveDashboard }) => {
  const [useTabelog, setUseTabelog] = useState<boolean>(false);
  const [useRecruit, setUseRecruit] = useState<boolean>(false);
  const [useSitest, setUseSitest] = useState<boolean>(false);
  const [useTakeoutDashboard, setUseTakeoutDashboard] = useState<boolean>(
    false
  );
  const [useTakeout, setUseTakeout] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    tabelog: availableTabelog,
    recruit: availableRectuit,
    sitest: availableSitest,
    takeout: availableTakeout,
    externalTakeout: availableExternalTakeout,
  } = AGENCY[window.location.hostname].options;

  const hasAvailabeOptions =
    availableTabelog ||
    availableRectuit ||
    availableSitest ||
    availableTakeout ||
    availableExternalTakeout;

  const enableTabelog = () => {
    setLoading(true);
    tabelogRepository
      .enable(isAgency, String(storeId))
      .then(() => {
        setUseTabelog(true);
        setErrorMsg(null);
      })
      .catch(() => setErrorMsg("外部サイト連携機能の有効化に失敗しました。"))
      .finally(() => setLoading(false));
  };

  const enableRecruit = () => {
    setLoading(true);
    recruitRepository
      .enable(isAgency, String(storeId))
      .then(() => {
        setUseRecruit(true);
        setErrorMsg(null);
      })
      .catch(() => setErrorMsg("求人管理機能の有効化に失敗しました。"))
      .finally(() => setLoading(false));
  };

  const enableSitest = () => {
    setLoading(true);
    sitestRepository
      .enable(isAgency, String(storeId))
      .then(() => {
        setErrorMsg(null);
        setUseSitest(true);
      })
      .catch(() =>
        setErrorMsg(
          "サイト解析機能の有効化及びアカウントの発行に失敗しました。サイト解析画面を利用できない場合は、運営にお問い合わせください。"
        )
      )
      .finally(() => setLoading(false));
  };

  const enableTakeout = (useAsEc: boolean) => {
    setLoading(true);
    takeoutRepository
      .enable(isAgency, String(storeId), useAsEc)
      .then(() => {
        setErrorMsg(null);
        setUseTakeout(true);
      })
      .catch(() => setErrorMsg("テイクアウト機能の有効化に失敗しました。"))
      .finally(() => setLoading(false));
  };

  const enableExternalTakeoutDashboard = () => {
    setLoading(true);
    externalTakeoutRepository
      .enable(isAgency, String(storeId))
      .then(() => {
        setUseTakeoutDashboard(true);
        setErrorMsg(null);
      })
      .catch(() =>
        setErrorMsg("外部アカウント連携機能の有効化に失敗しました。")
      )
      .finally(() => setLoading(false));
  };

  return (
    <Segment padded css={{ margin: "0 auto !important" }}>
      <Header content="オプション選択" dividing />
      {availableTabelog && (
        <Segment vertical>
          <Header icon="food" content="外部サイト連携機能を利用する" />
          <OptionStatus
            enabled={useTabelog}
            toEnable={enableTabelog}
            isAgency={isAgency}
          />
        </Segment>
      )}
      {availableRectuit && (
        <Segment vertical>
          <Header icon="male" content="求人管理機能を利用する" />
          <OptionStatus
            enabled={useRecruit}
            toEnable={enableRecruit}
            isAgency={isAgency}
          />
        </Segment>
      )}
      {availableSitest && (
        <Segment vertical>
          <Header icon="chart pie" content="サイト解析機能を利用する" />
          <OptionStatus
            enabled={useSitest}
            toEnable={enableSitest}
            isAgency={isAgency}
          />
        </Segment>
      )}
      {availableExternalTakeout && (
        <Segment vertical>
          <Header icon="chart pie" content="外部管理画面　注文履歴を利用する" />
          <OptionStatus
            enabled={useTakeoutDashboard}
            toEnable={enableExternalTakeoutDashboard}
            isAgency={isAgency}
          />
        </Segment>
      )}
      {availableTakeout && (
        <Segment vertical>
          <Header icon="chart pie" content="テイクアウト機能を利用する" />
          <OptionTakeoutStatus
            enabled={useTakeout}
            toEnable={enableTakeout}
            isAgency={isAgency}
          />
        </Segment>
      )}
      {!hasAvailabeOptions ? (
        <Message info content="利用可能なオプションはありません" />
      ) : null}
      {errorMsg && <Message error content={errorMsg} />}
      <div css={{ textAlign: "center", margin: "16px" }}>
        <Form.Button
          color="blue"
          content="店舗ページへ"
          icon="right arrow"
          labelPosition="right"
          onClick={() => moveDashboard(storeId)}
        />
      </div>
      <Loader active={loading} content="有効にしています" />
    </Segment>
  );
};

export default OptionForm;
