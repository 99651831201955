import React, { useEffect, useState } from "react";
import { Card, Button, Loader, Message } from "semantic-ui-react";

import ContactForm from "domain/model/ContactForm";
import { contactService } from "domain/service";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { clipboardWriteText } from "utils/copyText";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

export const ContactFormCards: React.FC<Props> = ({ isAgency, storeId }) => {
  const [contactForms, setContactForms] = useState<ContactForm[] | null>(null);
  const history = useHistory();

  useEffect(() => {
    const fetchAllContactForm = () => {
      contactService
        .fetchAllContactForm(isAgency, storeId)
        .then((res) => setContactForms(res));
    };
    fetchAllContactForm();
  }, [isAgency, storeId]);

  if (contactForms === null) {
    return <Loader active />;
  }
  if (contactForms.length === 0) {
    return <Message content="問い合わせフォームがありません" />;
  }

  return (
    <Card.Group centered>
      {contactForms.map((form: ContactForm) => {
        const handleClickDetailButton = () => {
          const formId = form.id;
          const detailUrl = isAgency
            ? generatePath(routes.agency.contactFormEdit.pathname, {
                storeId,
                formId,
              })
            : generatePath(routes.store.contactFormEdit.pathname, {
                formId,
              });
          history.push(detailUrl);
        };

        const handleClickCopy = () => {
          clipboardWriteText(form.dom);
        };

        return (
          <Card css={{ width: "auto !important" }} key={form.id}>
            <Card.Content>
              <Card.Header textAlign="center">{form.formName}</Card.Header>
            </Card.Content>
            <Card.Content extra>
              <div
                css={{
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                <div css={{ marginRight: "16px" }}>
                  <Button
                    basic
                    color="green"
                    content="HTMLをコピー"
                    onClick={handleClickCopy}
                  />
                </div>
                <Button
                  labelPosition="right"
                  icon="right chevron"
                  content="フォーム詳細へ"
                  onClick={handleClickDetailButton}
                />
              </div>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};
