import React, { useState } from "react";

import { Formik } from "formik";
import { Form, Header, Message } from "semantic-ui-react";

import * as Yup from "yup";
import MESSAGE from "config/message.json";

import { subscRepository } from "domain/repository";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

const UpdateSubscForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const [isUpdateOk, setIsUpdateOk] = useState<boolean | null>(null);
  const UpdateMessage = () => {
    if (isUpdateOk === null) {
      return null;
    }
    if (isUpdateOk) {
      return (
        <Message
          positive
          content="サブスクストアページとの連携が完了しました"
        />
      );
    }
    return <Message negative content="更新に失敗しました" />;
  };
  return (
    <>
      <Header content="サブスクストア 同期するページの更新" dividing />
      <Message content="同期するページのURLを入力してください(https://...)" />
      <Formik
        initialValues={{
          url: "",
        }}
        onSubmit={(values, formikHelpers) => {
          setTimeout(() => {
            subscRepository
              .update({
                isAgency,
                storeId,
                subscUrl: values.url,
              })
              .then(() => setIsUpdateOk(true))
              .catch(() => setIsUpdateOk(false))
              .finally(() => {
                formikHelpers.setSubmitting(false);
              });
          }, 5000);
        }}
        validationSchema={Yup.object().shape({
          url: Yup.string()
            .required(MESSAGE.ERROR.NO_INPUT)
            .url(MESSAGE.ERROR.INVALID_URL),
        })}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
          <Form>
            <Form.Input
              name="url"
              error={errors.url}
              value={values.url}
              placeholder="https://..."
              onChange={handleChange}
            />
            <div css={{ display: "flex", justifyContent: "center" }}>
              <Form.Button
                type="submit"
                content="更新"
                color="blue"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              />
            </div>
            <UpdateMessage />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateSubscForm;
