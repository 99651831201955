import React, { useEffect } from "react";
import { NewsContainer, StoreContainer } from "containers";
import { Button, Loader, Segment, Message } from "semantic-ui-react";
import { NewsList } from "components/Post/News/Index/NewsList";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

export const Container: React.FC<Props> = ({ isAgency, storeId }) => {
  const storeContainer = StoreContainer.useContainer();
  const { currentStore } = storeContainer;
  const newsContainer = NewsContainer.useContainer();
  const { news, fetchNews, hasMoreNews, loading } = newsContainer;

  useEffect(() => {
    fetchNews(isAgency, storeId);
  }, [fetchNews, isAgency, storeId]);

  if (news === null) {
    return <Loader content="お知らせ一覧取得中" active />;
  }

  const registered = currentStore?.awsResource.domain !== null;

  return (
    <Segment>
      <Loader active={loading} size="large" />
      {registered ? null : (
        <Message
          negative
          content="サイトを公開するにはドメインの設定が必要です"
        />
      )}
      <NewsList newsList={news} isAgency={isAgency} storeId={storeId} />
      {hasMoreNews ? (
        <Button
          content="続きを読み込む"
          fluid
          onClick={() => {
            if (news === null) {
              return;
            }
            fetchNews(isAgency, storeId, news[news.length - 1].createdAtStr);
          }}
        />
      ) : null}
    </Segment>
  );
};
