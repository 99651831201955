import React, { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { orderStatuses, OrderStatus } from "domain/model/Order";
import styled from "@emotion/styled";

registerLocale("ja", ja);

type Props = {
  selectedDate: Dayjs | null;
  selectedOrderStatuses: OrderStatus[];
  onSelectDate: (date: Dayjs | null) => void;
  onSelectOrderStatuses: (orderStatuses: OrderStatus[]) => void;
};

export const SearchForm: React.FC<Props> = ({
  selectedDate,
  selectedOrderStatuses,
  onSelectDate,
  onSelectOrderStatuses,
}) => {
  const options = useMemo(() => {
    return (Object.entries(orderStatuses) as [OrderStatus, string][]).map(
      ([value, label]) => ({
        value,
        label,
      })
    );
  }, []);

  const selectedOrderStatusValues = options.filter(({ value }) =>
    selectedOrderStatuses.includes(value)
  );

  return (
    <Box>
      <FormField>
        <FormLabel>受取日</FormLabel>
        <DatePicker
          onChange={(date: Date) => {
            const newDate = dayjs(date);

            if (newDate.isValid()) {
              onSelectDate(newDate);
            } else {
              onSelectDate(null);
            }
          }}
          selected={selectedDate ? selectedDate.toDate() : null}
          dateFormat="yyyy年MM月dd日"
          locale="ja"
          css={{ border: "1px solid #ddd", borderRadius: 3, padding: 10 }}
        />
      </FormField>
      <FormField>
        <FormLabel>受注ステータス</FormLabel>
        <Select
          options={options}
          value={selectedOrderStatusValues}
          isMulti
          clearIndicator={null}
          onChange={(value) => {
            onSelectOrderStatuses(value.map(({ value }) => value));
          }}
          css={{ display: "inline-block", minWidth: "300px" }}
          noOptionsMessage={() => "選択できる項目がありません"}
        />
      </FormField>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const FormField = styled.div`
  margin-right: 10px;
`;

const FormLabel = styled.div`
  margin-bottom: 5px;
  font-weight: bolder;
`;
