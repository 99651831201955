import React, { useEffect, useState } from "react";
import { generatePath, useParams, useHistory } from "react-router-dom";
import { Segment, Loader, Header, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import { messageTemplateService } from "domain/service";
import { messageTemplateRepository } from "domain/repository";
import { UserContainer, StoreContainer } from "containers";
import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import routes from "utils/routes";
import MessageTemplate from "domain/model/MessageTemplate";
import MessageTemplateForm from "components/SiteManage/Content/TakeoutMessageTemplate/Form";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore } = StoreContainer.useContainer();
  const history = useHistory();
  const { storeId, messageType } = useParams<
    StoreIdParam & { messageType: string }
  >();
  const [loading, setLoading] = useState(true);
  const [
    messageTemplate,
    setMessageTemplate,
  ] = useState<MessageTemplate | null>(null);

  const agencyEnabled = AGENCY[window.location.hostname].options.takeout;

  useEffect(() => {
    messageTemplateService
      .fetchAllMessageTemplate(isAgency, storeId, messageType)
      .then((result) => {
        setMessageTemplate(result);
        setLoading(false);
      });
  }, [isAgency, storeId, messageType]);

  if (!agencyEnabled) {
    return <NotValidOption heading="テイクアウト機能" />;
  }

  if (!currentStore || loading) {
    return <Loader active />;
  }

  const { useTakeout } = currentStore.property;

  if (!messageTemplate) {
    return <Message content="メッセージテンプレートが登録されていません" />;
  }

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  return (
    <>
      <Header content="メッセージテンプレート設定" dividing />
      <MessageTemplateForm
        initialValues={{
          title: messageTemplate.title,
          body: messageTemplate.body,
          active: messageTemplate.active,
        }}
        currentStore={currentStore}
        handleSubmit={async ({ values }) => {
          await messageTemplateRepository.updateMessageTemplate(
            isAgency,
            storeId,
            messageTemplate.messageType,
            values
          );

          const path = isAgency
            ? generatePath(routes.agency.takeoutMessageTemplate.pathname, {
                storeId,
              })
            : generatePath(routes.store.takeoutMessageTemplate.pathname, {
                storeId,
              });

          history.push(path);
        }}
      />
    </>
  );
};

const TakeoutMessageTemplateEdit = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="メッセージテンプレート設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .takeoutMessagePage()
        .build("メッセージテンプレート設定")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};

export default TakeoutMessageTemplateEdit;
