import React, { ErrorInfo } from "react";
import { Button, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageContainer header="エラーが発生しました">
          <Segment>
            <Button as="a" href="/" color="blue" content="トップページへ" />
          </Segment>
        </PageContainer>
      );
    }

    return this.props.children;
  }
}
