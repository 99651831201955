import { Expose, Type } from "class-transformer";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ja";
import Product from "domain/model/Product";
dayjs.extend(relativeTime);
dayjs.locale("ja");

export const orderStatuses = {
  new: "新規受注",
  processing: "受注承諾",
  completed: "完了",
  cancel: "キャンセル済み",
};

export type OrderStatus = keyof typeof orderStatuses;
export type PaymentType = "store" | "card" | "cod";

export default class Order {
  @Expose() id: number;
  @Expose({ name: "order_number" }) orderNumber: string;
  @Expose({ name: "order_status" }) orderStatus: OrderStatus;
  @Expose({ name: "order_price" }) orderPrice: number;
  @Expose({ name: "ordered_at" }) orderedAt: string;
  @Expose({ name: "completed_at" }) completedAt: string;
  @Expose({ name: "payment_type" }) paymentType: PaymentType;
  @Expose({ name: "settlement" }) settlement: Boolean;
  @Expose() email: string;
  @Expose({ name: "taker_name" }) name: string;
  @Expose({ name: "takeout_date" }) takeoutDate: string;
  @Expose({ name: "takeout_time" }) takeoutTime: string;
  @Expose() tel: string;
  @Expose({ name: "shipping_zip_code" }) shippingZipCode: string | null;
  @Expose({ name: "shipping_address" }) shippingAddress: string | null;
  @Expose() remarks: string;

  @Expose({ name: "order_details" })
  @Type(() => OrderDetail)
  orderDetails: OrderDetail[];

  hasShippingInformation(): boolean {
    return this.shippingZipCode != null && this.shippingAddress != null;
  }

  /**
   * 受注日時の現在時刻からの差分時間を表示する
   */
  get orderDateTimeFromNow() {
    return dayjs(this.orderedAt).fromNow();
  }

  /**
   * 受注日時を 06:00〜29:30 の時刻範囲で返す（YYYY年MM月DD日 HH:mm）
   */
  get orderDateTimeRenderValue() {
    const datetime = dayjs(this.orderedAt);
    return this.getDateTimeRenderStr(datetime);
  }

  /**
   * 受け渡し時刻の現在時刻からの差分時間を表示する
   */
  get takeoutDateTimeFromNow() {
    // 簡易 EC の場合、受け渡し日時は現状未使用なので空文字を返す
    if (!this.takeoutTime) return "";

    const datetime = dayjs(`${this.takeoutDate} ${this.takeoutTime}`);
    return datetime.fromNow();
  }

  /**
   * 受け渡し日時を 06:00〜29:30 の時刻範囲で返す（YYYY年MM月DD日 HH:mm）
   */
  get takeoutDateTimeRendeValue() {
    // 簡易 EC の場合、受け渡し日時は現状未使用なので空文字を返す
    if (!this.takeoutTime) return "";

    // takeoutTime は最初から 06:00〜29:30 の範囲で設定されている
    const datetime = dayjs(`${this.takeoutDate} ${this.takeoutTime}`);
    return this.getDateTimeRenderStr(datetime);
  }

  /**
   * 時刻が 00:**〜05:** の場合、1日前の 24:**〜29:** に変換して YYYY年MM月DD日 HH:mm 形式で日時を返す
   * keepDate:
   * @param datetime Dayjs のインスタンス
   * @param keepDate もともと日付が正しい場合、これを true とすると日付は維持し、時刻のみ 00:00 → 24:00 のように変換する
   * @param dateFormat 日付フォーマットを指定（なお時刻フォーマットは固定）
   */
  private getDateTimeRenderStr(datetime: Dayjs, dateFormat = "YYYY年MM月DD日") {
    const timeFormat = "HH:mm";
    const h = datetime.hour();
    if (6 <= h) {
      return datetime.format(`${dateFormat} ${timeFormat}`);
    } else {
      const dateStr = datetime.subtract(1, "day").format(dateFormat);
      // 愚直な実装だが手っ取り早くこうする
      const timeStr = datetime
        .format(timeFormat)
        .replace("00:", "24:")
        .replace("01:", "25:")
        .replace("02:", "26:")
        .replace("03:", "27:")
        .replace("04:", "28:")
        .replace("05:", "29:");
      return `${dateStr} ${timeStr}`;
    }
  }
}

export class OrderDetail {
  @Expose()
  @Type(() => Product)
  product: Product;
  @Expose() number: number;
  @Expose({ name: "unit_price" }) unitPrice: number;
}

export const makeStatusLabel = (orderStatus: OrderStatus) =>
  orderStatuses[orderStatus];

export const makePaymentTypeLabel = (
  paymentType: PaymentType,
  settlement: Boolean
) => {
  switch (paymentType) {
    case "store":
      return "店舗で決済";
    case "card":
      return `クレジットカード${settlement ? "(支払い済)" : "(未決済)"}`;
    case "cod":
      return `代引き決済`;
  }
};
