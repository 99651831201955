// import { plainToClass } from "class-transformer";
import api from "utils/api";
import endpoint from "utils/endpoint";

// import Version from "domain/model/Version";

class VersionRepository {
  rollback(
    isAgency: boolean,
    storeId: string | undefined,
    versionHash: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.version.rollback(storeId)
        : endpoint.store.version.rollback();
    return api.post(url, { rollback: { version: versionHash } });
  }

  async update(
    isAgency: boolean,
    storeId: string | undefined,
    versionHash: string,
    title: string
  ) {
    const data = { version: { title } };
    let url;
    if (isAgency && storeId) {
      url = endpoint.agency.version.root(storeId);
    } else {
      url = endpoint.store.version.root();
    }
    return api.put(url, data, { params: { version_hash: versionHash } });
  }

  async createVersion(
    isAgency: boolean,
    storeId: string | undefined,
    file: File,
    title?: string
  ) {
    const formData = new FormData();
    title && formData.append("zip[title]", title);
    formData.append("zip[file]", file);

    const url =
      isAgency && storeId
        ? endpoint.agency.version.root(storeId)
        : endpoint.store.version.root();

    return api
      .post(url, formData, {
        headers: {
          "Content-Type": "application/x-zip-compresser",
        },
        // 時間がかかることがあるので長くしておく
        timeout: 30 * 60 * 1000,
      })
      .then(() => {});
  }

  updateSourceFile(
    isAgency: boolean,
    storeId: string | undefined,
    {
      filepath,
      source,
      versionHash,
      title,
    }: { filepath: string; source: string; versionHash: string; title: string },
    deployment: boolean
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.version.source(storeId)
        : endpoint.store.version.source();
    const body = {
      source: {
        filepath,
        content: source,
        version_hash: versionHash,
        title,
        deployment,
      },
    };
    return api.put(url, body);
  }
}

export default new VersionRepository();
