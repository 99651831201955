import React from "react";

import { Table } from "semantic-ui-react";
import Post from "domain/model/Post";
import { NewsItem } from "components/Post/News/Index/NewsItem";
import { useHistory, generatePath } from "react-router-dom";
import routes from "utils/routes";

type Props = {
  newsList: Post[];
  isAgency: boolean;
  storeId: string | undefined;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* index */}
        </Table.HeaderCell>
        <Table.HeaderCell css={{ width: "20%" }}>サムネイル</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "20%" }}>タイトル</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "20%" }}>カテゴリ</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>ステータス</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>投稿日</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* 編集ボタン */}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const NewsList: React.FC<Props> = ({ isAgency, newsList, storeId }) => {
  const history = useHistory();
  return (
    <>
      <Table>
        <TableHeader />
        <Table.Body>
          {newsList.map((news, i) => {
            const handleEdit = () => {
              const editUrl = isAgency
                ? generatePath(routes.agency.newsEdit.pathname, {
                    storeId,
                    slug: news.slug,
                  })
                : generatePath(routes.store.newsEdit.pathname, {
                    slug: news.slug,
                  });
              history.push(editUrl);
            };
            return (
              <NewsItem
                news={news}
                index={i}
                key={news.slug}
                handleEdit={handleEdit}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
