import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import FileUploadResult from "domain/model/FileUploadResult";

type ProductParams = {
  code: string;
  name: string;
  price: number;
  display: boolean;
  displayOrder: number;
  description: string;
  cautions: string;
  image: string;
  categoryIds: number[];
};

class ProductRepository {
  async createProduct(
    isAgency: boolean,
    storeId: string | undefined,
    params: ProductParams
  ) {
    const product = {
      code: params.code,
      name: params.name,
      price: params.price,
      display: params.display,
      display_order: params.displayOrder,
      description: params.description,
      cautions: params.cautions,
      image: params.image,
      product_category_ids: params.categoryIds,
    };

    const url =
      isAgency && storeId
        ? endpoint.agency.product.create(storeId)
        : endpoint.store.product.create();

    return api.post(url, { product });
  }

  async updateProduct(
    isAgency: boolean,
    storeId: string | undefined,
    id: string,
    params: ProductParams
  ) {
    const product = {
      code: params.code,
      name: params.name,
      price: params.price,
      display: params.display,
      display_order: params.displayOrder,
      description: params.description,
      cautions: params.cautions,
      image: params.image,
      product_category_ids: params.categoryIds,
    };

    const url =
      isAgency && storeId
        ? endpoint.agency.product.update(storeId, id)
        : endpoint.store.product.update(id);

    return api.patch(url, { product });
  }

  async uploadProductFile(
    isAgency: boolean,
    storeId: string | undefined,
    file: File
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("dry_run", "false");
    const url =
      isAgency && storeId
        ? endpoint.agency.product.import(storeId)
        : endpoint.store.product.import();

    return api.post(url, formData).then((res) =>
      plainToClass(FileUploadResult, res.data, {
        excludeExtraneousValues: true,
      })
    );
  }

  async uploadProductCategoryFile(
    isAgency: boolean,
    storeId: string | undefined,
    file: File
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("dry_run", "false");
    const url =
      isAgency && storeId
        ? endpoint.agency.productCategory.import(storeId)
        : endpoint.store.productCategory.import();

    return api.post(url, formData).then((res) =>
      plainToClass(FileUploadResult, res.data, {
        excludeExtraneousValues: true,
      })
    );
  }
}

export default new ProductRepository();
