import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter,
  useHistory,
} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import Layout from "components/Layout";
import routes from "utils/routes";
import PrivateRoute from "components/utils/PrivateRoute";
import SetCurrentStore from "components/utils/SetCurrentStore";
import {
  MetaContainer,
  UserContainer,
  StoreContainer,
  VersionContainer,
  ArticleContainer,
  NewsContainer,
} from "containers";
import { ErrorBoundary } from "components/ErrorBoundary";
import { resetPending } from "utils/api";

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <MetaContainer.Provider>
            <UserContainer.Provider>
              <StoreContainer.Provider>
                <HistoryListener>
                  <Layout>
                    <Switch>
                      {/* App.tsxで、このオブジェクトからmapでRouteコンポーネントを生成する
                       * exactをつけないと定義順で上がルーティングでは優先されるので注意
                       * TODO: Object.valuesは順不同なので、ルーティングをオブジェクトに依存しないようにする */}
                      {Object.values(routes.common).map((route, index) => (
                        <Route
                          key={index}
                          path={route.pathname}
                          component={route.Component}
                          exact
                        />
                      ))}
                      {Object.values(routes.adminAgency).map((route, index) => (
                        <PrivateRoute
                          key={index}
                          path={route.pathname}
                          component={route.Component}
                        />
                      ))}
                      {Object.values(routes.agency).map(
                        ({ pathname, Component }, index) => (
                          <PrivateRoute
                            key={index}
                            path={pathname}
                            component={(props) => (
                              <VersionContainer.Provider>
                                <ArticleContainer.Provider>
                                  <NewsContainer.Provider>
                                    <SetCurrentStore>
                                      <Component {...props} />
                                    </SetCurrentStore>
                                  </NewsContainer.Provider>
                                </ArticleContainer.Provider>
                              </VersionContainer.Provider>
                            )}
                          />
                        )
                      )}
                      {Object.values(routes.store).map(
                        ({ pathname, Component }, index) => (
                          <PrivateRoute
                            key={index}
                            path={pathname}
                            component={(props) => (
                              <VersionContainer.Provider>
                                <ArticleContainer.Provider>
                                  <NewsContainer.Provider>
                                    <SetCurrentStore>
                                      <Component {...props} />
                                    </SetCurrentStore>
                                  </NewsContainer.Provider>
                                </ArticleContainer.Provider>
                              </VersionContainer.Provider>
                            )}
                          />
                        )
                      )}
                      <Route
                        render={() => (
                          <Redirect to={routes.common.signInAgency.pathname} />
                        )}
                      />
                    </Switch>
                  </Layout>
                </HistoryListener>
              </StoreContainer.Provider>
            </UserContainer.Provider>
          </MetaContainer.Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default App;

const HistoryListener: React.FC = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      // ページ遷移時にリクエストのペンディングリストをリセットする
      resetPending();
    });
  });
  return <>{children}</>;
};
