import React, { useState } from "react";
import { Menu, MenuItemProps } from "semantic-ui-react";

const MenuItems = {
  POST_CONTENT: "投稿内容",
  POST_PREVIEW: "プレビュー",
  SEO_PREFERENCES: "SEO設定",
} as const;

type MenuItemType = typeof MenuItems[keyof typeof MenuItems];

/**
 * 投稿画面のタブメニューの情報を管理する
 * activeMenu: 選択されているメニュー
 * MenuItems: メニュー項目
 * renderMenu: メニューコンポーネントを返す関数
 */
export const usePostMenu = () => {
  const [activeMenu, setActiveMenu] = useState<MenuItemType>(
    MenuItems.POST_CONTENT
  );
  const handleMenuClick = (
    _: React.MouseEvent<HTMLAnchorElement>,
    { name }: MenuItemProps
  ) => {
    if (name !== undefined) {
      setActiveMenu(name as MenuItemType);
    }
  };

  const renderMenu = () => (
    <Menu pointing secondary>
      <Menu.Item
        name={MenuItems.POST_CONTENT}
        active={activeMenu === MenuItems.POST_CONTENT}
        onClick={handleMenuClick}
      />
      <Menu.Item
        name={MenuItems.POST_PREVIEW}
        active={activeMenu === MenuItems.POST_PREVIEW}
        onClick={handleMenuClick}
      />
      <Menu.Item
        name={MenuItems.SEO_PREFERENCES}
        active={activeMenu === MenuItems.SEO_PREFERENCES}
        onClick={handleMenuClick}
      />
    </Menu>
  );

  return {
    activeMenu,
    MenuItems,
    renderMenu,
  };
};
