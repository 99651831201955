import api from "utils/api";
import endpoint from "utils/endpoint";

class TakeoutRepository {
  /**
   * 代理店ユーザーのみ利用可能
   * テイクアウト機能を有効化する
   */
  public async enable(isAgency: boolean, storeId: string, useAsEc: boolean) {
    const url = endpoint.agency.takeout.use(storeId);
    return api.post(url, { as_ec: useAsEc });
  }
}

export default new TakeoutRepository();
