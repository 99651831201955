import React from "react";
import { OrderItem } from "components/Order/Index/OrderItem";
import { Message, Table } from "semantic-ui-react";
import Order from "domain/model/Order";

type Props = {
  orders: Order[];
  isAgency: boolean;
  storeId: string | undefined;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>受注番号</Table.HeaderCell>
        <Table.HeaderCell>受注日時</Table.HeaderCell>
        <Table.HeaderCell>受け渡し日時</Table.HeaderCell>
        <Table.HeaderCell>注文商品</Table.HeaderCell>
        <Table.HeaderCell>決済方法</Table.HeaderCell>
        <Table.HeaderCell>ステータス</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const OrderList: React.FC<Props> = ({ isAgency, orders, storeId }) => {
  if (orders.length === 0) {
    return <Message content="受注情報がありません" />;
  }

  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {orders.map((order, i) => (
            <OrderItem
              key={order.id}
              order={order}
              storeId={storeId}
              isAgency={isAgency}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
