/**
 * [key: string]: {
 *  [key: string]: {
 *    [key: string]: Function;
 *  };
 * };
 */
const endpoint = {
  common: {
    logo: {
      root: (hostname: string) => `/${hostname}/logo.png`,
    },
    favicon: {
      root: (hostname: string) => `/${hostname}/favicon.ico`,
    },
    password: {
      resetRequest: () => "/password_reset_request",
      reset: () => "/password_reset",
    },
  },
  agency: {
    agencies: { root: () => "/agency/agencies" },
    stores: {
      root: () => "/agency/stores",
      show: (storeId: string) => `/agency/stores/${storeId}`,
      delete: (storeId: number) => `/agency/stores/${storeId}`,
      accessKey: (storeId: string) => `/agency/stores/${storeId}/access_key`,
    },
    staff: {
      root: () => "/agency/staff",
      update: (staffId: string) => `/agency/staff/${staffId}`,
    },
    domain: {
      contact: (storeId: string) => `/agency/stores/${storeId}/domain`,
      availability: () => "/agency/domain_availability",
      externalDomain: (storeId: string) =>
        `/agency/stores/${storeId}/external_domain`,
      externalDnsRecordset: (storeId: string) =>
        `/agency/stores/${storeId}/external_dns_recordset`,
    },
    notification: {
      root: () => "/agency/notifications",
      show: (notificationId: string) =>
        `/agency/notifications/${notificationId}`,
      store: (storeId: string) => `/agency/stores/${storeId}/notifications`,
    },
    user: {
      signIn: () => "/agency/login",
      passwordUpdate: () => "/agency/password",
      me: () => "/agency/me",
    },
    version: {
      root: (storeId: string) => `/agency/stores/${storeId}/versions`,
      archive: (storeId: string) =>
        `/agency/stores/${storeId}/version_archives`,
      rollback: (storeId: string) => `/agency/stores/${storeId}/rollbacks`,
      source: (storeId: string) => `/agency/stores/${storeId}/source_files`,
    },
    article: {
      root: (storeId: string) => `/agency/stores/${storeId}/articles`,
      edit: (storeId: string, filePath: string) =>
        `/agency/stores/${storeId}/articles/edit?filepath=${filePath}`,
      template: (storeId: string) => `/agency/stores/${storeId}/template`,
      applyTemplate: (storeId: string) =>
        `/agency/stores/${storeId}/apply_template`,
    },
    subcategory: {
      root: (storeId: string) => `/agency/stores/${storeId}/subcategories`,
      article: (storeId: string) =>
        `/agency/stores/${storeId}/subcategories?subcategory[category]=articles`,
      news: (storeId: string) =>
        `/agency/stores/${storeId}/subcategories?subcategory[category]=news`,
    },
    news: {
      root: (storeId: string) => `/agency/stores/${storeId}/news`,
      edit: (storeId: string, filePath: string) =>
        `/agency/stores/${storeId}/news/edit?filepath=${filePath}`,
      template: (storeId: string) => `/agency/stores/${storeId}/template`,
      applyTemplate: (storeId: string) =>
        `/agency/stores/${storeId}/apply_template`,
    },
    media: {
      root: (storeId: string) => `/agency/stores/${storeId}/images`,
      delete: (mediaId: string, storeId: string) =>
        `/agency/stores/${storeId}/images/${mediaId}`,
    },
    contact: {
      root: (storeId: string) => `/agency/stores/${storeId}/common_contacts`,
      update: (contactId: string, storeId: string) =>
        `/agency/stores/${storeId}/common_contacts/${contactId}`,
      forms: (storeId: string) => `/agency/stores/${storeId}/contact_forms`,
      formShow: (formId: string, storeId: string) =>
        `/agency/stores/${storeId}/contact_forms/${formId}`,
    },
    jobFeed: {
      feed: (storeId: string, feedId: string) =>
        `/agency/stores/${storeId}/job_feeds/${feedId}`,
      feeds: (storeId: string) => `/agency/stores/${storeId}/job_feeds`,
    },
    jobOffer: {
      use: (storeId: string) => `/agency/stores/${storeId}/recruit`,
      root: (storeId: string) =>
        `/agency/stores/${storeId}/recruitment_applicants`,
      form: (storeId: string, formId: string) =>
        `/agency/stores/${storeId}/recruit_forms/${formId}`,
      forms: (storeId: string) => `/agency/stores/${storeId}/recruit_forms`,
    },
    sitest: {
      root: (storeId: string) => `/agency/stores/${storeId}/sitest_account`,
      update: (storeId: string) =>
        `/agency/stores/${storeId}/sitest_account?use_sitest=true`,
    },
    tabelog: {
      use: (storeId: string) => `/agency/stores/${storeId}/tabelog`,
      update: (storeId: string) => `/agency/stores/${storeId}/tabelog_menu`,
    },
    takeout: {
      use: (storeId: string) => `/agency/stores/${storeId}/takeout`,
    },
    retty: {
      update: (storeId: string) => `/agency/stores/${storeId}/retty_menu`,
    },
    subsc: {
      update: (storeId: string) => `/agency/stores/${storeId}/subsc`,
    },
    dns: {
      use: (storeId: string) => `/agency/stores/${storeId}/dns_management`,
      recordSet: (storeId: string) =>
        `/agency/stores/${storeId}/dns_record_sets`,
    },
    //UIにはないが,将来実装する可能性があるので残す
    sns: {
      root: (storeId: string) => `/agency/stores/${storeId}/social_accounts`,
      delete: (id: string, storeId: string) =>
        `/agency/stores/${storeId}/social_accounts/${id}`,
      auth: (storeId: string) => `/agency/stores/${storeId}/omniauth_uris`,
    },
    sitemap: {
      root: (storeId: string) => `/agency/stores/${storeId}/sitemap`,
    },
    seo: {
      root: (storeId: string) => `/agency/stores/${storeId}/seo_preferences`,
    },
    google: {
      oauth: (storeId: string) => `/agency/stores/${storeId}/google/oauth_uri`,
      analytics: (storeId: string) =>
        `/agency/stores/${storeId}/google_analytics`,
      analyticsManage: (storeId: string) =>
        `/agency/stores/${storeId}/ga_management`,
    },
    favicon: {
      update: (storeId: string) => `/agency/stores/${storeId}/favicon`,
    },
    takeoutLogin: {
      root: (storeId: string) => `/agency/stores/${storeId}/takeout_login`,
      use: (storeId: string) => `/agency/stores/${storeId}/takeout_dashboard`,
    },
    takeoutSales: {
      root: (storeId: string) => `/agency/stores/${storeId}/takeout_sales`,
      updated: (storeId: string) =>
        `/agency/stores/${storeId}/takeout_sales_updated`,
    },
    externalTakeoutAuth: {
      root: (storeId: string) => `/agency/stores/${storeId}/takeout_auth`,
    },
    resty: {
      root: (storeId: string) => `/agency/stores/${storeId}/resty`,
      auth: (storeId: string) => `/agency/stores/${storeId}/resty_auth`,
      storeProfile: (storeId: string) =>
        `/agency/stores/${storeId}/store_profile`,
    },
    product: {
      root: (storeId: string) => `/agency/stores/${storeId}/products`,
      create: (storeId: string) => `/agency/stores/${storeId}/products`,
      show: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/products/${id}`,
      update: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/products/${id}`,
      delete: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/products/${id}`,
      import: (storeId: string) => `/agency/stores/${storeId}/products/import`,
      export: (storeId: string) => `/agency/stores/${storeId}/products/export`,
    },
    productCategory: {
      root: (storeId: string) => `/agency/stores/${storeId}/product_categories`,
      import: (storeId: string) =>
        `/agency/stores/${storeId}/product_categories/import`,
      export: (storeId: string) =>
        `/agency/stores/${storeId}/product_categories/export`,
    },
    order: {
      root: (storeId: string) => `/agency/stores/${storeId}/orders`,
      summary: (storeId: string) => `/agency/stores/${storeId}/orders/summary`,
      show: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/orders/${id}`,
      toProcessing: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/orders/${id}/status/processing`,
      toCompleted: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/orders/${id}/status/completed`,
      toCancel: (storeId: string, id: string) =>
        `/agency/stores/${storeId}/orders/${id}/status/cancel`,
    },
    businessDayType: {
      root: (storeId: string) => `/agency/stores/${storeId}/business_day_types`,
      updateAll: (storeId: string) =>
        `/agency/stores/${storeId}/business_day_types/update_all`,
    },
    messageTemplate: {
      root: (storeId: string) => `/agency/stores/${storeId}/message_templates`,
      show: (storeId: string, messageType: string) =>
        `/agency/stores/${storeId}/message_templates/${messageType}`,
      update: (storeId: string, messageType: string) =>
        `/agency/stores/${storeId}/message_templates/${messageType}`,
    },
    basicAuth: {
      use: (storeId: string) => `/agency/stores/${storeId}/use_basic_auth`,
      show: (storeId: string) => `/agency/stores/${storeId}/basic_auth`,
      update: (storeId: string) => `/agency/stores/${storeId}/basic_auth`,
    },
  },

  store: {
    user: {
      signIn: () => "/store/login",
      passwordUpdate: () => "/store/password",
      me: () => "/store/me",
    },
    staff: {
      root: () => "/store/staff",
      update: (staffId: string) => `/store/staff/${staffId}`,
    },
    version: {
      root: () => "/store/versions",
      archive: () => "/store/version_archives",
      rollback: () => "/store/rollbacks",
      source: () => "/store/source_files",
    },
    article: {
      root: () => "/store/articles",
      edit: (filePath: string) => `/store/articles/edit?filepath=${filePath}`,
      template: () => "/store/template",
      applyTemplate: () => `/store/apply_template`,
    },
    subcategory: {
      root: () => "store/subcategories",
      article: () => "store/subcategories?subcategory[category]=articles",
      news: () => "store/subcategories?subcategory[category]=news",
    },
    news: {
      root: () => "/store/news",
      edit: (filePath: string) => `/store/news/edit?filepath=${filePath}`,
      template: () => "/store/template",
      applyTemplate: () => `/store/apply_template`,
    },
    media: {
      root: () => "/store/images",
      delete: (mediaId: string) => `/store/images/${mediaId}`,
    },
    sitest: {
      root: () => "/store/sitest_account",
    },
    contact: {
      root: () => "/store/common_contacts",
      forms: () => "/store/contact_forms",
      formShow: (formId: string) => `/store/contact_forms/${formId}`,
    },
    jobFeed: {
      feed: (feedId: string) => `/store/job_feeds/${feedId}`,
      feeds: () => `/store/job_feeds`,
    },
    jobOffer: {
      use: () => "/store/recruit",
      root: () => "/store/recruitment_applicants",
      form: (formId: string) => `/store/recruit_forms/${formId}`,
      forms: () => "/store/recruit_forms",
    },
    notification: {
      root: () => "/store/notifications",
    },
    //UIにはないが,将来実装する可能性があるので残す
    sns: {
      root: () => "/store/social_accounts",
      auth: () => "/store/omniauth_uris",
      delete: (id: string) => `/store/social_accounts/${id}`,
    },
    tabelog: {
      use: () => "/store/tabelog",
      update: () => "/store/tabelog_menu",
    },
    retty: {
      update: () => "/store/retty_menu",
    },
    subsc: {
      update: () => "/store/subsc",
    },
    store: {
      root: () => "/store/store",
    },
    seo: {
      root: () => "/store/seo_preferences",
    },
    google: {
      analytics: () => "/store/google_analytics",
      analyticsManage: () => "/store/ga_management",
    },
    takeoutLogin: {
      root: () => "/store/takeout_login",
      use: () => "/store/takeout_dashboard",
    },
    takeoutSales: {
      root: () => "/store/takeout_sales",
      updated: () => "/store/takeout_sales_updated",
    },
    externalTakeoutAuth: {
      root: () => `/stores/takeout_auth`,
    },
    resty: {
      root: () => `/store/resty`,
      auth: () => `/store/resty_auth`,
      storeProfile: () => `/store/store_profile`,
    },
    product: {
      root: () => "/store/products",
      create: () => "/store/products",
      show: (id: string) => `/store/products/${id}`,
      update: (id: string) => `/store/products/${id}`,
      delete: (id: string) => `/store/products/${id}`,
      import: () => "/store/products/import",
      export: () => "/store/products/export",
    },
    productCategory: {
      root: () => "/store/product_categories",
      import: () => "/store/product_categories/import",
      export: () => "/store/product_categories/export",
    },
    order: {
      root: () => "/store/orders",
      summary: () => "/store/orders/summary",
      show: (id: string) => `/store/orders/${id}`,
      toProcessing: (id: string) => `/store/orders/${id}/status/processing`,
      toCompleted: (id: string) => `/store/orders/${id}/status/completed`,
      toCancel: (id: string) => `/store/orders/${id}/status/cancel`,
    },
    businessDayType: {
      root: () => "/store/business_day_types",
      updateAll: () => "/store/business_day_types/update_all",
    },
    messageTemplate: {
      root: () => "/store/message_templates",
      show: (messageType: string) => `/store/message_templates/${messageType}`,
      update: (messageType: string) =>
        `/store/message_templates/${messageType}`,
    },
  },
} as const;

export default endpoint;
