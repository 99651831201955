import React from "react";
import { useMediaQuery } from "react-responsive";

import {
  Segment,
  List,
  Label,
  Message,
  Divider,
  Header,
  Icon,
} from "semantic-ui-react";

import { AwsStatus, DomainRegistrar } from "config/raven";
import AwsResource from "domain/model/AwsResource";

const DOMAIN_REGISTRAR: Record<
  DomainRegistrar,
  { NAME: string; URL: string }
> = require("config/domain.json").DOMAIN_REGISTRAR;
const AWS_STATUS: Record<
  "EXTERNAL_REGISTRAR" | "INTERNAL_REGISTRAR",
  Record<AwsStatus, string>
> = require("config/domain.json").AWS_STATUS;

type Props = {
  awsResource: AwsResource;
  awsState: AwsStatus;
};

export const DomainInformation: React.FC<Props> = ({
  awsResource,
  awsState,
}) => {
  const { domain, externalRegistrar, homepageDomain } = awsResource;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (domain === null) {
    return null;
  }

  return (
    <Segment>
      <Header content="ドメイン情報" />
      <Message
        content={
          AWS_STATUS[
            externalRegistrar ? "EXTERNAL_REGISTRAR" : "INTERNAL_REGISTRAR"
          ][awsState]
        }
        success={awsState === "setup_completed"}
        warning={awsState !== "setup_completed"}
      />
      <List divided>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="ドメイン名"
          />
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {domain.name}
          </div>
        </List.Item>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="公開用ドメイン名"
          />
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {homepageDomain}
          </div>
        </List.Item>
        {externalRegistrar ? (
          <List.Item
            as="a"
            href={DOMAIN_REGISTRAR[domain.registrar].URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Label
              color="blue"
              horizontal
              css={{ width: isMobile ? "40%" : "20%" }}
              content="レジストラ"
            />
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {DOMAIN_REGISTRAR[domain.registrar].NAME}
              <Icon css={{ marginLeft: "0.3rem !important" }} name="external" />
            </div>
          </List.Item>
        ) : (
          <List.Item>
            <Label
              color="blue"
              horizontal
              css={{ width: isMobile ? "40%" : "20%" }}
              content="レジストラ"
            />
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Amazon Route 53
            </div>
          </List.Item>
        )}
        <Divider fitted />
      </List>
    </Segment>
  );
};
