import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import PageContainer from "components/lib/PageContainer";
import { googleService } from "domain/service";

import GoogleOauth from "domain/model/Google/GoogleOauth";
import GaResource from "domain/model/Google/GaResource";
import { MessageModal } from "components/SiteManage/Content/Google/MessageModal";

import { GoogleCertificate } from "components/SiteManage/Content/Google/GoogleCertificate";
import { GoogleAnalyticsRegister } from "components/SiteManage/Content/Google/GoogleAnalyticsRegister";

import { Segment, Loader, Message, Header } from "semantic-ui-react";
import { googleRepository } from "domain/repository";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { UserContainer } from "containers";

export const GoogleCooperation: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<{ storeId: string }>();

  const [isGaProjectCreated, setIsGaProjectCreated] = useState<boolean | null>(
    null
  );

  const [authUrl, setAuthUrl] = useState<string | null>(null);
  const [authUpdatedAt, setAuthUpdatedAt] = useState<string | null>(null);

  const [createGaProjectError, setCreateGaProjectError] = useState<boolean>(
    false
  );
  const [revokeError, setRevokeError] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isAuthenticated = !!authUpdatedAt;

  const updatedAtMsg = isAuthenticated
    ? `最終認証日
    : ${dayjs(Number(authUpdatedAt) * 1000).format("YYYY/MM/DD HH:mm")}`
    : "認証していません";

  const buttonTitle = isAuthenticated ? "再認証" : "認証";

  useEffect(() => {
    const fetchGoogle = () => {
      // Googleアカウント認証に関する情報を取得
      googleService.fetchGoogleOauthUrl(storeId).then((res: GoogleOauth) => {
        setAuthUpdatedAt(res.updatedAt);
        setAuthUrl(res.authUri);
      });

      // Google Analyticsのプロジェクトを作成済みか取得
      googleService
        .fetchGaResource(storeId)
        .then((res: GaResource) => {
          if (res.accountId) {
            setIsGaProjectCreated(true);
          } else {
            setIsGaProjectCreated(false);
          }
        })
        .catch(() => {
          setIsGaProjectCreated(false);
        });
    };
    fetchGoogle();
  }, [storeId]);

  const createGaProject = () => {
    setIsLoading(true);
    setIsModalOpen(true);
    googleRepository
      .createGaProject(storeId)
      .then(() => {
        setCreateGaProjectError(false);
        setIsGaProjectCreated(true);
      })
      .catch(() => {
        setCreateGaProjectError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const revokeGoogle = () => {
    setIsLoading(true);
    googleRepository
      .revokeGoogleConnection(storeId)
      .then(() => {
        setAuthUpdatedAt(null);
        setRevokeError(false);
      })
      .catch(() => setRevokeError(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <PageContainer
      header="Google連携設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("Google連携設定")}
    >
      {isLoading ? (
        <Loader active />
      ) : (
        <>
          <Segment>
            <Header content="Googleアカウント認証" />
            <MessageModal
              open={isModalOpen}
              createGaError={createGaProjectError}
              setIsModalOpen={setIsModalOpen}
            />
            <GoogleCertificate
              isAuthenticated={isAuthenticated}
              updatedAtMsg={updatedAtMsg}
              buttonTitle={buttonTitle}
              authUrl={authUrl}
              revokeGoogle={revokeGoogle}
            />
            {revokeError ? (
              <div css={{ textAlign: "center", marginTop: "10px" }}>
                <Message negative content="連携解除に失敗しました" />
              </div>
            ) : null}
          </Segment>
          {isAuthenticated ? (
            <Segment>
              <Header content="Google Analytics" />
              <GoogleAnalyticsRegister
                isGaProjectCreated={isGaProjectCreated}
                createGaProject={createGaProject}
              />
            </Segment>
          ) : null}
        </>
      )}
    </PageContainer>
  );
};
