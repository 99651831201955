import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { agencyRepository } from "domain/repository";

import routes from "utils/routes";
import Message from "config/message.json";

import { Form, Segment, Loader, Label } from "semantic-ui-react";

const initialValues = {
  agencyName: "",
  agencyEmail: "",
  staffName: "",
  staffEmail: "",
  password: "",
  passwordConfirmation: "",
};

const validationSchema = Yup.object().shape({
  agencyName: Yup.string().required(Message.ERROR.NO_INPUT),
  agencyEmail: Yup.string()
    .required(Message.ERROR.NO_INPUT)
    .email(Message.ERROR.INVALID_EMAIL),
  staffName: Yup.string().required(Message.ERROR.NO_INPUT),
  staffEmail: Yup.string()
    .required(Message.ERROR.NO_INPUT)
    .email(Message.ERROR.INVALID_EMAIL),
  password: Yup.string().required(Message.ERROR.NO_INPUT),
  passwordConfirmation: Yup.string()
    .required(Message.ERROR.NO_INPUT)
    .oneOf([Yup.ref("password")], "パスワードが一致しません"),
});

export const AgencyNewForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isAgencyEmailExist, setIsAgencyEmailExist] = useState<boolean>(false);
  const [isStaffEmailExist, setIsStaffEmailExist] = useState<boolean>(false);

  const history = useHistory();
  const agencyIndexPath = routes.adminAgency.agencyIndex.pathname;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      setLoading(true);
      setIsAgencyEmailExist(false);
      setIsStaffEmailExist(false);
      agencyRepository
        .createAgencyStore({
          agencyName: values.agencyName,
          agencyEmail: values.agencyEmail,
          staffName: values.staffName,
          staffEmail: values.staffEmail,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
        })
        .then(() => {
          setLoading(false);
          history.push(agencyIndexPath);
        })
        .catch((err: any) => {
          setLoading(false);
          if (
            err?.response?.data?.error?.agency?.email !== undefined &&
            err?.response?.data?.error?.agency?.email[0] ===
              "has already been taken"
          ) {
            setIsAgencyEmailExist(true);
          }

          if (
            err?.response?.data?.error?.staff !== undefined &&
            err?.response?.data?.error?.staff?.email[0] ===
              "has already been taken"
          ) {
            setIsStaffEmailExist(true);
          }
        });
    },
    validateOnBlur: true,
    validateOnChange: false,
    initialStatus: { success: "", error: "" },
  });

  return (
    <>
      {loading ? (
        <Loader active content="代理店を作成してます" />
      ) : (
        <Segment>
          <Form onSubmit={formik.handleSubmit}>
            <Segment vertical textAlign="center">
              <Form.Field required>
                <label>代理店名</label>
                <Form.Input
                  name="agencyName"
                  value={formik.values.agencyName}
                  onChange={formik.handleChange}
                  error={formik.errors.agencyName}
                />
              </Form.Field>
              <Form.Field required>
                <label>代理店メールアドレス</label>
                <Form.Input
                  name="agencyEmail"
                  value={formik.values.agencyEmail}
                  onChange={formik.handleChange}
                  error={formik.errors.agencyEmail}
                />
                {isAgencyEmailExist ? (
                  <Label color="red" pointing>
                    このメールアドレスは既に使用されています
                  </Label>
                ) : (
                  <></>
                )}
              </Form.Field>
            </Segment>
            <Segment vertical textAlign="center">
              <Form.Field required>
                <label>スタッフ名</label>
                <Form.Input
                  name="staffName"
                  value={formik.values.staffName}
                  onChange={formik.handleChange}
                  error={formik.errors.staffName}
                />
              </Form.Field>
              <Form.Field required>
                <label>スタッフメールアドレス</label>
                <Form.Input
                  name="staffEmail"
                  value={formik.values.staffEmail}
                  onChange={formik.handleChange}
                  error={formik.errors.staffEmail}
                />
                {isStaffEmailExist ? (
                  <Label color="red" pointing>
                    このメールアドレスは既に使用されています
                  </Label>
                ) : (
                  <></>
                )}
              </Form.Field>
              <Form.Field required>
                <label>パスワード</label>
                <Form.Input
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                />
              </Form.Field>
              <Form.Field required>
                <label>パスワード(再入力)</label>
                <Form.Input
                  name="passwordConfirmation"
                  type="password"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={formik.errors.passwordConfirmation}
                />
              </Form.Field>
              <Form.Button color="blue" type="submit">
                作成
              </Form.Button>
            </Segment>
          </Form>
        </Segment>
      )}
    </>
  );
};
