import React from "react";

import Contact from "domain/model/Contact";
import { Table, Button, Modal } from "semantic-ui-react";

type Props = {
  contact: Contact;
  contactNum: number;
  selectedContactId: string | undefined;
  setSelectedContactId: React.Dispatch<string>;
};

const nl2br = (text: any) => {
  if (typeof text !== "string") return text || "-";

  const lines = text.split("\n");
  return lines.map((l) => [l, <br />]).flat();
};

export const ContactItem: React.FC<Props> = ({
  contact,
  contactNum,
  selectedContactId,
  setSelectedContactId,
}) => {
  return (
    <Table.Row key={contactNum}>
      <Table.Cell>{contactNum + 1}</Table.Cell>
      <Table.Cell>{contact.createdAt}</Table.Cell>
      <Table.Cell>
        <Modal
          open={contact.contactIdef === selectedContactId}
          trigger={
            <Button
              color="blue"
              onClick={() => {
                setSelectedContactId(contact.contactIdef);
              }}
            >
              受信内容を見る
            </Button>
          }
        >
          <Modal.Header>お問い合わせ内容</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <dl>
                {contact.contents ? (
                  Object.keys(contact.contents).map((key) => {
                    const content =
                      (contact.contents as Record<string, any>)[key] || "-";
                    return [
                      <dt css={{ fontWeight: "bold" }}>{key}</dt>,
                      <dd css={{ margin: "0.5em 0 1em" }}>{nl2br(content)}</dd>,
                    ];
                  })
                ) : (
                  <p>データがありません</p>
                )}
              </dl>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              onClick={() => {
                setSelectedContactId("none");
              }}
            >
              閉じる
            </Button>
          </Modal.Actions>
        </Modal>
      </Table.Cell>
    </Table.Row>
  );
};
