import React, { useState } from "react";

import { StoreContainer, UserContainer } from "containers";

import PageContainer from "components/lib/PageContainer";
import {
  generatePath,
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { Button, Header, Loader, Message, Segment } from "semantic-ui-react";
import routes from "utils/routes";
import OptionStatus from "components/lib/OptionStatus";
import { dnsRepository } from "domain/repository";
import { DNSRecordSet } from "components/SiteManage/Content/DNSRecord/DNSRecordSet";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

type Props = { storeId: string; isAgency: boolean };
const Container: React.FC<Props> = ({ isAgency, storeId }) => {
  const storeContainer = StoreContainer.useContainer();
  const { currentStore, changeCurrentStore } = storeContainer;

  const [loading, setLoading] = useState(false);
  const [optionErrorMsg, setOptionErrorMsg] = useState<string | null>(null);

  if (!currentStore || loading) {
    return <Loader active />;
  }

  const useDns = currentStore.property.useDns;

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    setLoading(true);
    setOptionErrorMsg(null);
    dnsRepository
      .enable(storeId)
      .then(() => changeCurrentStore(isAgency, storeId))
      .catch(() => {
        setOptionErrorMsg(
          "有効化に失敗しました。DNSレコード管理機能を利用するには、ドメインを設定する必要があります。"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Segment>
        <Header content={`DNSレコード管理の利用状況`} dividing />
        <OptionStatus
          isAgency={true}
          enabled={useDns}
          toEnable={handleClickEnable}
        />
        {optionErrorMsg ? (
          <Message negative size="tiny" content={optionErrorMsg} />
        ) : null}
      </Segment>
      {useDns && (
        <Segment>
          <DNSRecordSet storeId={storeId} />
        </Segment>
      )}
    </>
  );
};

// 代理店用ページ
export const DNSRecord: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const history = useHistory();

  if (!isAgency || !storeId) {
    return <Redirect to={routes.store.dashboard.pathname} />;
  }

  return (
    <PageContainer
      header="DNSレコード管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("Google連携設定")}
    >
      <Segment>
        <Container isAgency={isAgency} storeId={storeId} />
      </Segment>
    </PageContainer>
  );
};
