import { useState, useEffect } from "react";

import StoreProfile from "domain/model/Resty";
import { restyService } from "domain/service";

/**
 * Resty予約情報を取得する
 * 予約情報が入っているか確認する
 */

export const useRestyStoreProfile = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [
    restyStoreProfile,
    setRestyStoreProfile,
  ] = useState<StoreProfile | null>(null);

  useEffect(() => {
    const fetchStoreProfile = async () => {
      restyService
        .fetchStoreProfile({
          isAgency,
          storeId,
        })
        .then((restyStoreProfile) => {
          setRestyStoreProfile(restyStoreProfile);
        });
    };
    fetchStoreProfile();
  }, [isAgency, storeId]);

  return { restyStoreProfile };
};
