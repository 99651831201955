import React from "react";
import { Modal, Header, Button } from "semantic-ui-react";

export const Category = {
  ARTICLE: "記事",
  NEWS: "お知らせ",
} as const;

type CategoryType = typeof Category[keyof typeof Category];

type Props = {
  category: CategoryType;
  handleTemplatePageButton: () => void;
};

export const NoTemplateModal: React.FC<Props> = ({
  handleTemplatePageButton,
  category,
}) => {
  return (
    <Modal open>
      <Modal.Header content="テンプレートを設定してください" />
      <Modal.Content>
        <Modal.Description>
          <Header
            size="small"
            content={`${category}機能を使用するにはテンプレートを設定する必要があります。`}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleTemplatePageButton}
          content="テンプレート設定ページへ"
          color="blue"
          icon="right arrow"
          labelPosition="right"
        />
      </Modal.Actions>
    </Modal>
  );
};
