import React, { useState, useEffect } from "react";
import { useHistory, useParams, generatePath } from "react-router-dom";

import {
  Segment,
  Button,
  Loader,
  Message,
  Confirm,
  Form,
} from "semantic-ui-react";

import { Formik } from "formik";

import routes from "utils/routes";

import { FormValues } from "domain/model/ContactFormTypes";
import { contactRepository } from "domain/repository";
import { contactService } from "domain/service";

import { validationSchema } from "components/Contact/lib/form";
import { ContactFormContent } from "components/Contact/lib/ContactFormContent";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

export const EditContactForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const history = useHistory();
  const { formId } = useParams<{ formId: string }>();

  const [initialValues, setInitialValues] = useState<FormValues>({
    formContent: {
      formName: "",
      pubEmail: "",
      redirectUrl: "",
      forms: [],
    },
  });

  const [updateError, setUpdateError] = useState<boolean>();
  const [deleteError, setDeleteError] = useState<boolean>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);

  const indexPath = isAgency
    ? generatePath(routes.agency.contactFormIndex.pathname, { storeId })
    : routes.store.contactFormIndex.pathname;

  useEffect(() => {
    const fetchContact = () => {
      contactService.fetchContactForm(isAgency, storeId, formId).then((res) => {
        // requiredがfalseの場合、nullが入ってるのでnonnull化する
        const nonnullForms = res.forms.map((form) => ({
          ...form,
          required: form.required ? true : false,
        }));
        setInitialValues({
          formContent: {
            formName: res.formName,
            pubEmail: res.pubEmail,
            forms: nonnullForms,
            redirectUrl: res.redirectUrl,
          },
        });
      });
    };
    fetchContact();
  }, [isAgency, storeId, formId]);

  const FormMessage = () => {
    if (updateError === true) {
      return <Message negative content="更新に失敗しました" />;
    } else if (deleteError === true) {
      return <Message negative content="削除に失敗しました" />;
    } else {
      return <></>;
    }
  };

  if (isDeleting) {
    return <Loader active content="削除しています" />;
  }

  if (isUpdating) {
    return <Loader active content="更新しています" />;
  }

  return (
    <Segment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsDeleting(false);
          setIsUpdating(true);

          contactRepository
            .updateContactForm({
              isAgency,
              storeId,
              formName: values.formContent.formName,
              pubEmail: values.formContent.pubEmail,
              forms: values.formContent.forms,
              redirectUrl: values.formContent.redirectUrl,
              formId,
            })
            .then((form) => history.push(indexPath, { savedFormDom: form.dom }))
            .catch(() => setUpdateError(true))
            .finally(() => setIsUpdating(false));
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <ContactFormContent isEdit />
              <div css={{ textAlign: "right" }}>
                <Button
                  type="button"
                  color="red"
                  content="削除"
                  onClick={() => setOpenDeleteConfirm(true)}
                />
                <Confirm
                  open={openDeleteConfirm}
                  onCancel={() => setOpenDeleteConfirm(false)}
                  onConfirm={() => {
                    setIsDeleting(true);
                    setIsUpdating(false);

                    contactRepository
                      .deleteContactForm({ storeId, formId })
                      .then(() => {
                        setDeleteError(false);
                        history.push(indexPath);
                      })
                      .catch(() => {
                        setDeleteError(true);
                      })
                      .finally(() => setIsDeleting(false));
                  }}
                  content="本当にこのフォームを削除しますか？"
                />
              </div>
              <FormMessage />
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
};
