import React from "react";
import { useParams } from "react-router-dom";
import { Message, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { NewForm } from "components/JobFeed/New/NewForm";
import { UserContainer } from "containers";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";

export const JobFeedNew = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  //const history = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  if (isJobOfferEnabled === false) {
    return <Message content="求人管理機能が利用できません" negative />;
  }

  return (
    <>
      <PageContainer
        header="求人フィード登録"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
          .jobFeedPage()
          .build("求人フィード登録")}
      >
        <Segment>
          <NewForm isAgency={isAgency} storeId={storeId} />
        </Segment>
      </PageContainer>
    </>
  );
};
export default JobFeedNew;
