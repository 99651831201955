import { generatePath } from "react-router-dom";
import { BreadcrumbSectionProps } from "semantic-ui-react";
import routes from "utils/routes";

type Breadcrumb = BreadcrumbSectionProps[];
type Bread = BreadcrumbSectionProps;

/**
 * 店舗系画面のパンくず情報を作成するBuilderクラス
 * new StoreBreadcrumbBuilder(isAgency, storeId)で、店舗トップのパンくずはデフォルトで作成される
 * あとは、ページのパンくずに必要な関数を呼び、build関数に現在のページ名を渡すとパンくずのデータ構造が生成される。
 * PageContainerのbreadcrumbsに渡すことを想定している
 * 例) [店舗トップ, お知らせ一覧, お知らせ投稿編集]という構造のパンくずが生成される
 *   new StoreBreadcrumbBuilder(isAgency, storeId)
 *      .newsPage()
 *      .build("お知らせ投稿編集")}
 */
export class StoreBreadcrumbBuilder {
  isAgency: boolean;
  storeId: string | undefined;
  breadcrumb: Breadcrumb;
  constructor(isAgency: boolean, storeId: string | undefined) {
    this.isAgency = isAgency;
    this.storeId = storeId;
    const storeTopUrl = isAgency
      ? generatePath(routes.agency.dashboard.pathname, { storeId })
      : routes.store.dashboard.pathname;
    this.breadcrumb = [
      {
        key: "top",
        content: "店舗トップ",
        link: true,
        href: storeTopUrl,
      },
    ];
  }
  _addBreadcrumb(bread: Bread) {
    this.breadcrumb = [...this.breadcrumb, bread];
  }

  /**
   * バージョン管理
   */
  versionPage() {
    const versionTopUrl = this.isAgency
      ? generatePath(routes.agency.versionIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.versionIndex.pathname;

    this._addBreadcrumb({
      key: "versionTop",
      content: "バージョン一覧",
      link: true,
      href: versionTopUrl,
    });
    return this;
  }

  /**
   * お知らせ管理
   */
  newsPage() {
    const newsTopUrl = this.isAgency
      ? generatePath(routes.agency.newsIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.newsIndex.pathname;

    this._addBreadcrumb({
      key: "newsTop",
      content: "お知らせ一覧",
      link: true,
      href: newsTopUrl,
    });
    return this;
  }

  /**
   * 記事管理
   */
  articlePage() {
    const articleTopUrl = this.isAgency
      ? generatePath(routes.agency.articleIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.articleIndex.pathname;

    this._addBreadcrumb({
      key: "articleTop",
      content: "記事一覧",
      link: true,
      href: articleTopUrl,
    });
    return this;
  }

  /**
   * 求人フィード管理
   */
  jobFeedPage() {
    const jobFeedTopUrl = this.isAgency
      ? generatePath(routes.agency.jobFeedIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.jobFeedIndex.pathname;

    this._addBreadcrumb({
      key: "jobFeedTop",
      content: "求人フィード一覧",
      link: true,
      href: jobFeedTopUrl,
    });
    return this;
  }

  /**
   * 求人票管理
   */
  jobOfferPage() {
    const jobOfferTopUrl = this.isAgency
      ? generatePath(routes.agency.jobOfferFormIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.jobOfferFormIndex.pathname;

    this._addBreadcrumb({
      key: "jobOfferTop",
      content: "求人票一覧",
      link: true,
      href: jobOfferTopUrl,
    });
    return this;
  }

  /**
   * 問い合わせフォーム管理
   */
  contactsFormPage() {
    const contactsFormTopUrl = this.isAgency
      ? generatePath(routes.agency.contactFormIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.contactFormIndex.pathname;

    this._addBreadcrumb({
      key: "contactsFormTop",
      content: "問い合わせフォーム一覧",
      link: true,
      href: contactsFormTopUrl,
    });
    return this;
  }

  /**
   * サイト管理
   */
  siteManagePage() {
    const siteManageUrl = this.isAgency
      ? generatePath(routes.agency.siteManage.pathname, {
          storeId: this.storeId,
        })
      : routes.store.siteManage.pathname;

    this._addBreadcrumb({
      key: "siteManageTop",
      content: "サイト管理",
      link: true,
      href: siteManageUrl,
    });
    return this;
  }

  /**
   * ドメイン設定
   */
  domainPage() {
    const domainPageUrl = this.isAgency
      ? generatePath(routes.agency.domain.pathname, {
          storeId: this.storeId,
        })
      : routes.store.domain.pathname;

    this._addBreadcrumb({
      key: "domainPage",
      content: "ドメイン設定",
      link: true,
      href: domainPageUrl,
    });
    return this;
  }

  /**
   * テイクアウト通知設定
   */
  takeoutMessagePage() {
    const takeoutMessagePageUrl = this.isAgency
      ? generatePath(routes.agency.takeoutMessageTemplate.pathname, {
          storeId: this.storeId,
        })
      : routes.store.takeoutMessageTemplate.pathname;

    this._addBreadcrumb({
      key: "takeoutMessage",
      content: "テイクアウト通知設定",
      link: true,
      href: takeoutMessagePageUrl,
    });
    return this;
  }

  /**
   * 売上一元管理
   */
  salesManagementPage() {
    const salesManagementPageUrl = this.isAgency
      ? generatePath(routes.agency.takeoutLogin.pathname, {
          storeId: this.storeId,
        })
      : routes.store.takeoutLogin.pathname;

    this._addBreadcrumb({
      key: "salesManagement",
      content: "売上一元管理",
      link: true,
      href: salesManagementPageUrl,
    });
    return this;
  }

  /**
   * 店舗情報
   */
  storeProfilePage() {
    const storeProfileTopUrl = this.isAgency
      ? generatePath(routes.agency.storeProfileShow.pathname, {
          storeId: this.storeId,
        })
      : routes.store.storeProfileEdit.pathname;

    this._addBreadcrumb({
      key: "storeProfile",
      content: "店舗情報",
      link: true,
      href: storeProfileTopUrl,
    });
    return this;
  }

  /**
   * 商品管理
   */
  productPage() {
    const productTopUrl = this.isAgency
      ? generatePath(routes.agency.productIndex.pathname, {
          storeId: this.storeId,
        })
      : routes.store.productIndex.pathname;

    this._addBreadcrumb({
      key: "productTop",
      content: "商品一覧",
      link: true,
      href: productTopUrl,
    });
    return this;
  }

  /**
   * @param currentContent 現在のページ名
   */
  build(currentContent: string): Breadcrumb {
    return [
      ...this.breadcrumb,
      { key: "current", content: currentContent, active: true },
    ];
  }
}
