import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Parser as JSON2CSVParser } from "json2csv";
import { encode } from "iconv-lite";

import Contact from "domain/model/Contact";
import { UserContainer } from "containers";
import { contactService } from "domain/service";
import { StoreIdParam } from "config/raven";

import PageContainer from "components/lib/PageContainer";
import { Loader, Button } from "semantic-ui-react";
import { ContactList } from "components/Contact/Index/ContactList";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const Container: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const [loading, setLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<Contact[] | null>(null);
  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [hasNextContents, setHasNextContents] = useState<boolean>(false);
  const [loadingReadMore, setLoadingReadMore] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  const fetchContacts = useCallback(
    (createdAt?: string) => {
      contactService
        .fetchAllContacts(isAgency, storeId, createdAt)
        .then((res) => {
          setLoading(false);
          if (res.length !== 0) {
            const lastCreatedAt = res[res.length - 1].createdAtStr;
            contactService
              .fetchAllContacts(isAgency, storeId, lastCreatedAt)
              .then((res) => {
                if (res.length === 0) {
                  setHasNextContents(false);
                } else {
                  setHasNextContents(true);
                }
              });
            setContacts((currentContacts) => {
              if (currentContacts === null) {
                return res;
              }
              return [...currentContacts, ...res];
            });
            setLoadingReadMore(false);
          } else {
            setHasNextContents(false);
          }
        });
    },
    [isAgency, storeId]
  );

  const downloadCSV = useCallback(async () => {
    setDownloading(true);
    const res = await contactService.fetchAllContacts(
      isAgency,
      storeId,
      undefined,
      10000
    );
    const contents = res
      .map((contact) => {
        const data = contact.contents as Record<string, any>;
        if (!data) {
          return null;
        }
        return Object.keys(data).reduce<Record<string, string>>(
          (result, key) => {
            return { ...result, [key]: String(data[key]) };
          },
          {}
        );
      })
      .filter((i) => i !== null);

    const parser = new JSON2CSVParser({
      header: true,
    });

    const csv = encode(parser.parse(contents), "Shift_JIS");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const downloaderEl = document.createElement("a");
    document.body.appendChild(downloaderEl);
    downloaderEl.download = "contacts.csv";
    downloaderEl.href = url;
    downloaderEl.click();
    downloaderEl.remove();
    URL.revokeObjectURL(url);

    setDownloading(false);
  }, [isAgency, storeId]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts, isAgency, storeId]);

  if (loading) {
    return <Loader active content="お問い合わせ一覧取得中" />;
  }
  if (!contacts) {
    return <p css={{ textAlign: "center" }}>お問い合わせはありません</p>;
  }

  return (
    <>
      <div css={{ textAlign: "right" }}>
        <Button
          disabled={downloading}
          icon="download"
          content={downloading ? "お待ちください…" : "CSVでダウンロード（β）"}
          compact
          onClick={downloadCSV}
        />
      </div>
      <ContactList
        contacts={contacts}
        selectedContactId={selectedContactId}
        setSelectedContactId={setSelectedContactId}
      />
      <div
        style={{
          textAlign: "center",
        }}
      >
        {hasNextContents ? (
          <Button
            color="blue"
            icon="search"
            content="さらに読み込む"
            loading={loadingReadMore}
            onClick={() => {
              setLoadingReadMore(true);
              fetchContacts(contacts.slice(-1)[0].createdAtStr);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export const ContactIndex: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  return (
    <PageContainer
      header="問い合わせ一覧"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "問い合わせ一覧"
      )}
    >
      <Container />
    </PageContainer>
  );
};
