import React, { useState, useEffect } from "react";
import JobOfferFormItem from "components/JobOffer/JobOfferFormIndex/JobOfferFormItem";
import { jobOfferService } from "domain/service";
import Form from "domain/model/JobOffer/Form";
import { Loader, Message, Card } from "semantic-ui-react";
import { useHistory, generatePath } from "react-router-dom";
import routes from "utils/routes";
import { clipboardWriteText } from "utils/copyText";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

const JobOfferFormList: React.FC<Props> = ({ isAgency, storeId }) => {
  const [jobOfferForms, setJobOfferForms] = useState<Form[] | null>(null);

  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  const history = useHistory();

  useEffect(() => {
    if (isJobOfferEnabled) {
      // 求人票情報取得
      jobOfferService
        .fetchForms(isAgency, storeId)
        .then((forms) => setJobOfferForms(forms));
    }
  }, [isAgency, isJobOfferEnabled, storeId]);

  if (isJobOfferEnabled === false) {
    return <Message content="求人管理機能が利用できません" negative />;
  }

  if (jobOfferForms === null) {
    return <Loader active />;
  }

  if (jobOfferForms.length === 0) {
    return <Message content="求人票がありません" />;
  }

  return (
    <Card.Group centered>
      {jobOfferForms.map((form) => {
        const handleClickDetailButton = () => {
          const formId = form.id;
          const detailUrl = isAgency
            ? generatePath(routes.agency.jobOfferFormEdit.pathname, {
                storeId,
                formId,
              })
            : generatePath(routes.store.jobOfferFormEdit.pathname, {
                formId,
              });
          history.push(detailUrl);
        };

        const handleClickCopy = () => {
          clipboardWriteText(form.dom);
        };
        return (
          <JobOfferFormItem
            form={form}
            key={form.id}
            handleClickCopy={handleClickCopy}
            handleClickDetailButton={handleClickDetailButton}
          />
        );
      })}
    </Card.Group>
  );
};

export default JobOfferFormList;
