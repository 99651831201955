import React, { useState } from "react";
import { Modal, Header, Button, Icon, Progress } from "semantic-ui-react";

export const Category = {
  ARTICLE: "記事",
  NEWS: "お知らせ",
} as const;

type CategoryType = typeof Category[keyof typeof Category];

const ProgressStatus = {
  NO_REQUEAT: "NO_REQUEST",
  REQUESTING: "REQUESTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
} as const;

type ProgressStatus = typeof ProgressStatus[keyof typeof ProgressStatus];

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  applyTemplate: () => Promise<any>;
  category: CategoryType;
};

export const ApplyTemplateModal: React.FC<Props> = ({
  open,
  setOpen,
  applyTemplate,
  category,
}) => {
  const [progressStatus, setProgressStatus] = useState<ProgressStatus>(
    ProgressStatus.NO_REQUEAT
  );
  const closeModal = () => {
    setOpen(false);
    setProgressStatus(ProgressStatus.NO_REQUEAT);
  };

  const apply = () => {
    setProgressStatus(ProgressStatus.REQUESTING);
    applyTemplate()
      .then(() => {
        setProgressStatus(ProgressStatus.SUCCESS);
      })
      .catch(() => {
        setProgressStatus(ProgressStatus.FAILURE);
      });
  };

  let ProgressBar = null;

  if (progressStatus === ProgressStatus.NO_REQUEAT) {
    ProgressBar = null;
  } else if (progressStatus === ProgressStatus.REQUESTING) {
    ProgressBar = (
      <Progress active percent={100}>
        適用中
      </Progress>
    );
  } else if (progressStatus === ProgressStatus.SUCCESS) {
    ProgressBar = (
      <Progress success percent={100}>
        テンプレートを適用しました
      </Progress>
    );
  } else {
    ProgressBar = (
      <Progress error percent={100}>
        テンプレートの適用に失敗しました
      </Progress>
    );
  }

  return progressStatus === ProgressStatus.NO_REQUEAT ? (
    <Modal open={open}>
      <Modal.Header content="テンプレートの一括適用" />
      <Modal.Content>
        <Modal.Description>
          <Header content={`全ての${category}にテンプレートを適用しますか？`} />
          <Icon name="warning sign" />
          元には戻せません
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} content="キャンセル" />
        <Button positive onClick={apply} content="適用" />
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal open={open}>
      <Modal.Header content="テンプレートの一括適用" />
      <Modal.Content>{ProgressBar}</Modal.Content>
      <Modal.Actions>
        <Button
          onClick={closeModal}
          content="閉じる"
          disabled={progressStatus === ProgressStatus.REQUESTING}
        />
      </Modal.Actions>
    </Modal>
  );
};
