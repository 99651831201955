import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import routes from "utils/routes";
import { Button, Message, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { EditForm } from "components/JobFeed/Edit/EditForm";
import { UserContainer } from "containers";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";

export const JobFeedEdit = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const history = useHistory();
  const { storeId, id } = useParams<StoreIdParam & { id: string }>();
  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  if (isJobOfferEnabled === false) {
    return <Message content="求人管理機能が利用できません" negative />;
  }

  return (
    <>
      <PageContainer
        header="求人フィード編集"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
          .jobFeedPage()
          .build("求人フィード編集")}
        action={
          <Button
            color="blue"
            content="一覧へ"
            onClick={() => {
              const jobFeedIndex = isAgency
                ? generatePath(routes.agency.jobFeedIndex.pathname, {
                    storeId,
                  })
                : routes.store.jobFeedIndex;
              history.push(jobFeedIndex);
            }}
          />
        }
      >
        <Segment>
          <EditForm isAgency={isAgency} storeId={storeId} id={id} />
        </Segment>
      </PageContainer>
    </>
  );
};
export default JobFeedEdit;
